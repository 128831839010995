import { connect } from 'react-redux';
import ResetPasswordPage from './ResetPasswordPage';
import { resetPassword } from '../../store/accountStore/actions';

const mapStateToProps = ({ accountStore: { passwordChanged } }) => ({
  passwordChanged,
});

const mapDispatchToProps = { resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
