import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import LocationInactiveImg from '../../assets/svgs/location-inactive.svg';
import AddTimeInactiveImg from '../../assets/svgs/add-time-inactive.svg';
import { ReactSVG } from 'react-svg';
import TimeInputPolyfill from 'react-time-input-polyfill';
import Spinner from '../../components/Spinner';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ResetPopup from '../../components/Popups/ResetPopup';
import MinusTimePopup from '../../components/Popups/MinusTimePopup';

let interval;
let timerTime = 0;
const pad = (number) => {
  return number < 10 ? '0' + number : number;
};

const convertToAMPM = (hours) => {
  return hours % 12 || 12;
};

const daysAgo = (dataNow, startTime) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();

  const setDay = new Date(
    startTime.y,
    startTime.m,
    startTime.d,
    startTime.h,
    startTime.min,
    startTime.sec,
  );

  const difference = dataNow - +startTime.d;

  if (currentMonth === +startTime.m && currentYear === +startTime.y) {
    if (difference < 1) return 'Today';
    else if (difference === 1) return 'Yesterday';
    else if (difference < 7) {
      return days[setDay.getDay()];
    } else {
      return moment(setDay).format('MMMM DD');
    }
  } else {
    return moment(setDay).format('MMMM DD');
  }
};

const getDifferenceDate = (firstDate, secondDate) => {
  const ms = moment(firstDate, 'DD/MM/YYYY HH:mm:ss').diff(
    moment(secondDate, 'DD/MM/YYYY HH:mm:ss'),
  );
  const d = moment.duration(ms);
  const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
  const hours = +s.split(':')[0];
  const minutes = +s.split(':')[1];
  const seconds = +s.split(':')[2];

  return {
    h: hours,
    min: minutes,
    sec: seconds,
  };
};

export const TimeInput = ({
  label,
  value,
  disabled,
  onBlur,
  id,
  onInputChange,
}) => {
  return (
    <label id={id}>
      <span>{label}</span>
      <TimeInputPolyfill
        disabled={disabled}
        onChange={({ value }) => {
          onInputChange(value);
        }}
        onBlur={() => {
          onBlur({ target: { value } });
        }}
      />
    </label>
  );
};

class TimerPage extends React.Component {
  state = {
    isRunningTimer: false,
    fullTime: {},
    startTime: {},
    stopTime: {},
    showAddInputStart: false,
    showAddInputEnd: false,
    valueStartInput: null,
    valueStopInput: null,
    openPopup: false,
    openNotesPopup: false,
    openPopupReset: false,
    openMinusTimePopup: false,
    answers: [],
  };

  componentDidMount() {
    const { getActiveJob, getLocationById, match } = this.props;
    getActiveJob();

    getLocationById(match.params.locationId);
  }

  componentDidUpdate(prevProps) {
    const { activeJob, tasksCreated, history, match } = this.props;
    let start, startDay, startHour, startMin, startSec;
    let stop, stopDay, stopHour, stopMin, stopSec;

    if (
      prevProps.activeJob !== activeJob &&
      activeJob.length &&
      activeJob.find((job) => job.locationId === +match.params.locationId) &&
      isEmpty(this.state.startTime)
    ) {
      const index = activeJob.findIndex(
        (job) => job.locationId === +match.params.locationId,
      );
      let isTimerRunning = !Boolean(activeJob[index].endDate);
      if (activeJob[index].startDate) {
        start = new Date(activeJob[index].startDate);
        startDay = start.getDate();
        startHour = start.getHours();
        startMin = start.getMinutes();
        startSec = start.getSeconds();
      }
      if (activeJob[index].endDate) {
        stop = new Date(activeJob[index].endDate);
        stopDay = stop.getDate();
        stopHour = stop.getHours();
        stopMin = stop.getMinutes();
        stopSec = stop.getSeconds();
      }

      if (activeJob[index].startDate) {
        const difference = getDifferenceDate(
          activeJob[index].endDate
            ? new Date(
                stop.getFullYear(),
                stop.getMonth(),
                +stopDay,
                +stopHour,
                +stopMin,
                +stopSec,
              )
            : new Date(),
          new Date(
            start.getFullYear(),
            start.getMonth(),
            +startDay,
            +startHour,
            +startMin,
            +startSec,
          ),
        );

        this.setState(
          {
            startTime: {
              y: start.getFullYear(),
              m: start.getMonth(),
              d: +startDay,
              h: +startHour,
              min: +startMin,
              sec: +startSec,
            },
            stopTime: activeJob[index].endDate
              ? {
                  y: stop.getFullYear(),
                  m: stop.getMonth(),
                  d: +stopDay,
                  h: +stopHour,
                  min: +stopMin,
                  sec: +stopSec,
                }
              : {},
            fullTime: {
              h: difference.h,
              min: difference.min,
              sec: difference.sec,
            },
            isRunningTimer: isTimerRunning,
          },
          () => {
            this.setOnCloseAlert();

            if (isTimerRunning) {
              this.handleStart(true);
            } else if (
              !isEmpty(this.state.startTime) &&
              !isEmpty(this.state.stopTime)
            ) {
              const difference = getDifferenceDate(
                new Date(
                  this.state.stopTime.y,
                  this.state.stopTime.m,
                  this.state.stopTime.d,
                  this.state.stopTime.h,
                  this.state.stopTime.min,
                  this.state.stopTime.sec,
                ),
                new Date(
                  this.state.startTime.y,
                  this.state.startTime.m,
                  this.state.startTime.d,
                  this.state.startTime.h,
                  this.state.startTime.min,
                  this.state.startTime.sec,
                ),
              );

              timerTime =
                difference.h * 60 * 60 + difference.min * 60 + difference.sec;
              this.setState({
                fullTime: {
                  h: difference.h,
                  min: difference.min,
                  sec: difference.sec,
                },
              });
            }
          },
        );
      }
    }
  }

  componentWillUnmount() {
    timerTime = 0;
    clearInterval(interval);
    this.unsetCloseAlert();
  }

  handleStart = (first) => {
    const { activeJob, createJob, updateJob, match } = this.props;
    const { startTime, isTimerRunning } = this.state;
    if (isTimerRunning) return;

    const date = new Date();
    const newStartTime = {
      y: date.getFullYear(),
      m: date.getMonth(),
      d: date.getDate(),
      h: pad(date.getHours()),
      min: date.getMinutes(),
      sec: date.getSeconds(),
    };

    if (isEmpty(startTime)) {
      const difference = getDifferenceDate(
        date,
        new Date(
          newStartTime.y,
          newStartTime.m,
          newStartTime.d,
          newStartTime.h,
          newStartTime.min,
          newStartTime.sec,
        ),
      );
      this.setState(
        {
          isRunningTimer: true,
          startTime: newStartTime,
          fullTime: {
            h: difference.h,
            min: difference.min,
            sec: difference.sec,
          },
          showAddInputStart: false,
          showAddInputEnd: false,
          stopTime: {},
        },
        () => {
          createJob({
            locationId: match.params.locationId,
            payload: {
              startDate: date.toISOString(),
            },
          });
          this.setOnCloseAlert();
        },
      );
    } else {
      this.setState(
        {
          isRunningTimer: true,
          stopTime: {},
        },
        () => {
          let index = -1;
          if (activeJob.length) {
            index = activeJob.findIndex(
              (job) => job.locationId === +match.params.locationId,
            );
          }

          const startDate = new Date(
            startTime.y,
            startTime.m,
            startTime.d,
            startTime.h,
            startTime.min,
            startTime.sec,
          );

          if (index !== -1 && first !== true) {
            updateJob({
              jobId: activeJob[index].id,
              payload: {
                startDate: startDate.toISOString(),
                endDate: null,
              },
            });
          } else {
            if (first !== true) {
              updateJob({
                jobId: activeJob.id,
                payload: {
                  startDate: startDate.toISOString(),
                  endDate: null,
                },
              });
            }
          }
          this.setOnCloseAlert();
        },
      );

      const difference = getDifferenceDate(
        date,
        new Date(
          this.state.startTime.y,
          this.state.startTime.m,
          this.state.startTime.d,
          this.state.startTime.h,
          this.state.startTime.min,
          this.state.startTime.sec,
        ),
      );

      timerTime = difference.h * 60 * 60 + difference.min * 60 + difference.sec;
    }
    clearInterval(interval);
    interval = setInterval(this.incrementTimer, 1000);
  };

  handleMinusTimePopup = () => {
    this.setState({ openMinusTimePopup: !this.state.openMinusTimePopup });
  };

  handleResetPopup = () => {
    this.setState({ openPopupReset: !this.state.openPopupReset });
  };

  handleOpenNotesPopup = (answers) => {
    this.setState({
      openPopup: false,
      openNotesPopup: !this.state.openNotesPopup,
      answers: answers.length ? answers : [],
    });
  };

  handleReset = () => {
    const { deleteJob, activeJob, match } = this.props;
    this.setState(
      {
        isRunningTimer: false,
        startTime: {},
        stopTime: {},
        fullTime: {},
        openPopupReset: false,
      },
      () => {
        let index = -1;

        if (activeJob.length) {
          index = activeJob.findIndex(
            (job) => job.locationId === +match.params.locationId,
          );
        }
        if (index !== -1) {
          deleteJob(activeJob[index].id);
        } else {
          deleteJob(activeJob.id);
        }
        timerTime = 0;
        clearInterval(interval);
        this.unsetCloseAlert();
      },
    );
  };

  handleChangeStartTime = (event) => {
    const { activeJob, createJob, match, updateJob } = this.props;
    const { stopTime } = this.state;

    if (!event || !event.target || !event.target.value) return;
    const setDate = new Date(event.target.value);
    const hours = setDate.getHours();
    const minutes = setDate.getMinutes();

    const dateNow = new Date();
    // const hoursNow = +dateNow.getHours();

    let dateForStart = setDate.getDate();

    // if (hoursNow - +hours < 0) {
    //   dateForStart = dateNow.getDate() - 1;
    // }

    const difference = getDifferenceDate(
      dateNow,
      new Date(
        setDate.getFullYear(),
        setDate.getMonth(),
        setDate.getDate(),
        hours,
        minutes,
      ),
    );

    // if (difference.h < 0 || difference.min < 0 || difference.sec < 0) {
    //   StyledAlertMessage.error("You can only set today's time");
    //
    //   return;
    // }

    timerTime = difference.h * 60 * 60 + difference.min * 60 + difference.sec;

    this.setState(
      {
        startTime: {
          y: setDate.getFullYear(),
          m: setDate.getMonth(),
          d: dateForStart,
          h: hours,
          min: minutes,
          sec: 0,
        },
        fullTime: {
          h: difference.h,
          min: difference.min,
          sec: difference.sec,
        },
        showAddInputStart: false,
      },
      () => {
        let index = -1;
        if (activeJob.length) {
          index = activeJob.findIndex(
            (job) => job.locationId === +match.params.locationId,
          );
        }
        if (index !== -1) {
          updateJob({
            jobId: activeJob[index].id,
            payload: {
              startDate: new Date(
                setDate.getFullYear(),
                setDate.getMonth(),
                setDate.getDate(),
                hours,
                minutes,
              ).toISOString(),
              endDate: !isEmpty(stopTime)
                ? new Date(
                    stopTime.y,
                    stopTime.m,
                    stopTime.d,
                    stopTime.h,
                    stopTime.min,
                    stopTime.sec,
                  ).toISOString()
                : null,
            },
          });
        } else if (!activeJob.length && !isEmpty(activeJob)) {
          updateJob({
            jobId: activeJob.id,
            payload: {
              startDate: new Date(
                setDate.getFullYear(),
                setDate.getMonth(),
                setDate.getDate(),
                hours,
                minutes,
              ).toISOString(),
              endDate: !isEmpty(stopTime)
                ? new Date(
                    stopTime.y,
                    stopTime.m,
                    stopTime.d,
                    stopTime.h,
                    stopTime.min,
                    stopTime.sec,
                  ).toISOString()
                : null,
            },
          });
        } else {
          createJob({
            locationId: match.params.locationId,
            payload: {
              startDate: new Date(
                setDate.getFullYear(),
                setDate.getMonth(),
                setDate.getDate(),
                hours,
                minutes,
              ).toISOString(),
            },
          });
        }
      },
    );
  };

  handleChangeStartTimeSafari = (value) => {
    this.setState({
      valueStartInput: value,
    });
  };

  handleChangeStopTimeSafari = (value) => {
    this.setState({
      valueStopInput: value,
    });
  };

  handleChangeStopTime = (event) => {
    const { activeJob, updateJob, match } = this.props;
    const { startTime } = this.state;

    if (!event.target || !event.target.value) {
      const difference = getDifferenceDate(
        new Date(),
        new Date(
          this.state.startTime.y,
          this.state.startTime.m,
          this.state.startTime.d,
          this.state.startTime.h,
          this.state.startTime.min,
          this.state.startTime.sec,
        ),
      );
      this.setState(
        {
          stopTime: {},
          fullTime: {
            h: difference.h,
            min: difference.min,
            sec: difference.sec,
          },
        },
        () => {
          timerTime =
            difference.h * 60 * 60 + difference.min * 60 + difference.sec;
          clearInterval(interval);
          interval = setInterval(this.incrementTimer, 1000);
          const startDate = new Date(
            startTime.y,
            startTime.m,
            startTime.d,
            startTime.h,
            startTime.min,
            startTime.sec,
          );

          let index = -1;

          if (activeJob.length) {
            index = activeJob.findIndex(
              (job) => job.locationId === +match.params.locationId,
            );
          }

          if (index !== -1) {
            updateJob({
              jobId: activeJob[index].id,
              payload: {
                startDate: startDate.toISOString(),
                endDate: null,
              },
            });
          } else {
            updateJob({
              jobId: activeJob.id,
              payload: {
                startDate: startDate.toISOString(),
                endDate: null,
              },
            });
          }
        },
      );
      return;
    }
    const setDate = new Date(event.target.value);

    const hours = setDate.getHours();
    const minutes = setDate.getMinutes();

    // const hoursNow = +dateNow.getHours();
    let dateForStop = setDate.getDate();

    // if (hoursNow - +hours < 0) {
    //   dateForStop = +dateNow.getDate() - 1;
    // }

    const difference = getDifferenceDate(
      new Date(
        setDate.getFullYear(),
        setDate.getMonth(),
        setDate.getDate(),
        hours,
        minutes,
      ),
      new Date(
        this.state.startTime.y,
        this.state.startTime.m,
        this.state.startTime.d,
        this.state.startTime.h,
        this.state.startTime.min,
        this.state.startTime.sec,
      ),
    );

    // if (difference.h < 0 || difference.min < 0 || difference.sec < 0) {
    //   StyledAlertMessage.error('Stop time are bigger than start');
    //
    //   return;
    // }

    this.setState(
      {
        stopTime: {
          y: setDate.getFullYear(),
          m: setDate.getMonth(),
          d: dateForStop,
          h: hours,
          min: minutes,
          sec: 0,
        },
        fullTime: {
          h: difference.h,
          min: difference.min,
          sec: difference.sec,
        },
        showAddInputEnd: false,
        isRunningTimer: false,
      },
      () => {
        let index = -1;
        if (activeJob.length) {
          index = activeJob.findIndex(
            (job) => job.locationId === +match.params.locationId,
          );
        }
        const startDate = new Date(
          startTime.y,
          startTime.m,
          startTime.d,
          startTime.h,
          startTime.min,
          startTime.sec,
        );
        if (index !== -1) {
          updateJob({
            jobId: activeJob[index].id,
            payload: {
              startDate: startDate.toISOString(),
              endDate: new Date(
                setDate.getFullYear(),
                setDate.getMonth(),
                setDate.getDate(),
                hours,
                minutes,
              ).toISOString(),
            },
          });
        } else {
          updateJob({
            jobId: activeJob.id,
            payload: {
              startDate: startDate.toISOString(),
              endDate: new Date(
                setDate.getFullYear(),
                setDate.getMonth(),
                setDate.getDate(),
                hours,
                minutes,
              ).toISOString(),
            },
          });
        }

        timerTime =
          difference.h * 60 * 60 + difference.min * 60 + difference.sec;
        clearInterval(interval);
      },
    );
  };

  handleStop = () => {
    const { updateJob, activeJob, match } = this.props;
    const { startTime } = this.state;
    const date = new Date();

    this.setState(
      {
        isRunningTimer: false,
        stopTime: {
          y: date.getFullYear(),
          m: date.getMonth(),
          d: date.getDate(),
          h: pad(date.getHours()),
          min: date.getMinutes(),
          sec: date.getSeconds(),
        },
      },
      () => {
        let index = -1;

        if (activeJob.length) {
          index = activeJob.findIndex(
            (job) => job.locationId === +match.params.locationId,
          );
        }

        const startDate = new Date(
          startTime.y,
          startTime.m,
          startTime.d,
          startTime.h,
          startTime.min,
          startTime.sec,
        );

        if (index !== -1) {
          updateJob({
            jobId: activeJob[index].id,
            payload: {
              startDate: startDate.toISOString(),
              endDate: date.toISOString(),
            },
          });
        } else {
          updateJob({
            jobId: activeJob.id,
            payload: {
              startDate: startDate.toISOString(),
              endDate: date.toISOString(),
            },
          });
        }

        clearInterval(interval);
      },
    );
  };

  handlePopup = () => {
    const { history, match } = this.props;
    const { fullTime, isRunningTimer } = this.state;
    if (isRunningTimer) {
      this.handleStop();
    } else if (fullTime.h < 0 || fullTime.min < 0 || fullTime.sec < 0) {
      this.handleMinusTimePopup();
      return;
    }

    history.push(`/timer/${match.params.locationId}/save`);

    // this.setState({ openPopup: !this.state.openPopup });
  };

  incrementTimer = () => {
    timerTime++;
    const seconds = Math.floor(timerTime % 60);
    const minutes = Math.floor((timerTime / 60) % 60);
    const hours = Math.floor(timerTime / (60 * 60));

    this.setState({
      fullTime: {
        h: hours,
        min: minutes,
        sec: seconds,
      },
    });
  };
  setOnCloseAlert = () => {
    window.onbeforeunload = (e) =>
      (e.returnValue = 'No data has been saved . Are you sure?');
  };
  unsetCloseAlert = () => {
    window.onbeforeunload = null;
  };
  render() {
    const { location, isLoadingActive } = this.props;
    const {
      isRunningTimer,
      fullTime,
      startTime,
      stopTime,
      showAddInputStart,
      showAddInputEnd,
      valueStartInput,
      valueStopInput,
      openPopupReset,
      openMinusTimePopup,
    } = this.state;

    const ua = navigator.userAgent.toLowerCase();
    let isSafari = false;
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        isSafari = false;
      }
    }
    const dataNow = +new Date().getDate();

    // console.log('activeJob', activeJob);
    // const index = activeJob.findIndex(
    //   (job) => job.locationId === +match.params.locationId,
    // );
    // const isHidden =
    //   !isEmpty(activeJob) && activeJob.locationId !== +match.params.locationId;

    const dayLimit = new Date().toISOString().slice(0, 10);

    return (
      <WrapperAll>
        <div className="card transparent">
          {isEmpty(location) ? (
            <Spinner />
          ) : (
            <Fragment>
              <div className="icon">
                <ReactSVG className="location" src={LocationInactiveImg} />
              </div>
              <div>
                <p className="heading">
                  <span className="name">{location.name} </span>
                  <span>{location.address}</span> {location.city}
                  {location.province ? ',' : ''} {location.province}
                  {location.postalCode}
                </p>
              </div>
            </Fragment>
          )}
        </div>
        {isLoadingActive ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="card space-between">
              <div>
                <span>Start</span>
                {isEmpty(startTime) &&
                  !isRunningTimer && ( //|| isHidden
                    <ClickAwayListener
                      onClickAway={() =>
                        this.setState({ showAddInputStart: false })
                      }
                    >
                      <Time
                        hidden={showAddInputStart}
                        onClick={
                          () => this.setState({ showAddInputStart: true }) // !isHidden &&
                        }
                        // notSet={isHidden}
                      >
                        {isSafari ? (
                          <TimeInput
                            label="Label text"
                            id="start-time"
                            change="showAddInputStart"
                            value={valueStartInput}
                            // disabled={isHidden}
                            onBlur={this.handleChangeStartTime}
                            onInputChange={(newValue) =>
                              this.handleChangeStartTimeSafari(newValue)
                            }
                          />
                        ) : (
                          <input
                            type="datetime-local"
                            id="start-time"
                            max={`${dayLimit}T23:59`}
                            onInput={this.handleChangeStartTime}
                            onClick={() => {
                              setTimeout(() => {
                                document.querySelector('.card').click();
                              }, 1000);
                            }}
                          />
                        )}

                        {/*{!isHidden ? (*/}
                        {/*  <ReactSVG*/}
                        {/*    className="clock"*/}
                        {/*    src={AddTimeActiveImg}*/}
                        {/*    wrapper="span"*/}
                        {/*  />*/}
                        {/*) : (*/}
                        <ReactSVG
                          className="clock"
                          src={AddTimeInactiveImg}
                          wrapper="span"
                        />
                        {/*)}*/}
                        <span
                          className={
                            'active start' // isHidden ? 'active start hidden' : 'active start'
                          }
                        >
                          Add Time
                        </span>
                      </Time>
                    </ClickAwayListener>
                  )}
                {!isEmpty(startTime) && ( //&& !isHidden
                  <ClickAwayListener
                    onClickAway={() =>
                      this.setState({ showAddInputStart: false })
                    }
                  >
                    <TimeSet
                      hidden={showAddInputStart}
                      onClick={() => this.setState({ showAddInputStart: true })}
                    >
                      <div className="time">
                        {convertToAMPM(startTime.h) + ':' + pad(startTime.min)}
                        <span>{startTime.h >= 12 ? ' PM' : ' AM'}</span>
                      </div>
                      {isSafari ? (
                        <TimeInput
                          label="Label text"
                          id="start-time"
                          change="showAddInputStart"
                          value={valueStartInput}
                          onBlur={this.handleChangeStartTime}
                          onInputChange={(newValue) =>
                            this.handleChangeStartTimeSafari(newValue)
                          }
                        />
                      ) : (
                        <input
                          type="datetime-local"
                          id="start-time"
                          max={`${dayLimit}T23:59`}
                          onInput={this.handleChangeStartTime}
                          onClick={() => {
                            setTimeout(() => {
                              document.querySelector('.card').click();
                            }, 1000);
                          }}
                        />
                      )}
                      <span className="day">{daysAgo(dataNow, startTime)}</span>
                    </TimeSet>
                  </ClickAwayListener>
                )}
              </div>
              <div className="end-block">
                <span>End</span>
                {isEmpty(stopTime) &&
                  !isRunningTimer && ( //|| isHidden
                    <ClickAwayListener
                      onClickAway={() =>
                        this.setState({ showAddInputEnd: false })
                      }
                    >
                      <Time
                        end
                        notSet={isEmpty(startTime)} // || isHidden
                        hidden={showAddInputEnd}
                        onClick={() => {
                          if (!isEmpty(startTime))
                            //&& !isHidden
                            this.setState({ showAddInputEnd: true });
                        }}
                      >
                        <label>
                          <span>Label text</span>

                          {isSafari ? (
                            <TimeInput
                              value={valueStopInput}
                              label="Label text"
                              id="stop-time"
                              disabled={isEmpty(startTime)} //|| isHidden
                              change="showAddInputEnd"
                              onBlur={this.handleChangeStopTime}
                              onInputChange={(newValue) =>
                                this.handleChangeStopTimeSafari(newValue)
                              }
                            />
                          ) : (
                            <input
                              type="datetime-local"
                              id="stop-time"
                              max={`${dayLimit}T23:59`}
                              disabled={isEmpty(startTime)}
                              onInput={this.handleChangeStopTime}
                              onClick={() => {
                                setTimeout(() => {
                                  document.querySelector('.card').click();
                                }, 1000);
                              }}
                            />
                          )}
                        </label>
                        {/*{!isHidden ? (*/}
                        {/*  <ReactSVG*/}
                        {/*    className="clock"*/}
                        {/*    src={AddTimeActiveImg}*/}
                        {/*    wrapper="span"*/}
                        {/*  />*/}
                        {/*) : (*/}
                        <ReactSVG
                          className="clock"
                          src={AddTimeInactiveImg}
                          wrapper="span"
                        />
                        {/*)}*/}
                        <span
                          className={
                            'active stop' // isHidden ? 'active stop hidden' : 'active stop'
                          }
                        >
                          Add Time
                        </span>
                      </Time>
                    </ClickAwayListener>
                  )}
                {!isEmpty(stopTime) && ( //&& !isHidden
                  <ClickAwayListener
                    onClickAway={() =>
                      this.setState({ showAddInputEnd: false })
                    }
                  >
                    <TimeSet
                      hidden={showAddInputEnd}
                      onClick={() => this.setState({ showAddInputEnd: true })}
                    >
                      <div className="time">
                        {convertToAMPM(stopTime.h) + ':' + pad(stopTime.min)}
                        <span>{stopTime.h >= 12 ? ' PM' : ' AM'}</span>
                      </div>
                      {isSafari ? (
                        <TimeInput
                          label="Label text"
                          id="stop-time"
                          change="showAddInputEnd"
                          value={valueStopInput}
                          onBlur={this.handleChangeStopTime}
                          onInputChange={(newValue) =>
                            this.handleChangeStopTimeSafari(newValue)
                          }
                        />
                      ) : (
                        <input
                          type="datetime-local"
                          id="stop-time"
                          max={`${dayLimit}T23:59`}
                          disabled={isEmpty(startTime)}
                          onInput={this.handleChangeStopTime}
                          onClick={() => {
                            setTimeout(() => {
                              document.querySelector('.card').click();
                            }, 1000);
                          }}
                        />
                      )}
                      <span className="day">{daysAgo(dataNow, stopTime)}</span>
                    </TimeSet>
                  </ClickAwayListener>
                )}
              </div>
            </div>
            <div className="timer">
              {/*{!isHidden &&*/}
              {/*  isEmpty(fullTime) &&*/}
              {/*  !isEmpty(this.state.startTime) && <Spinner />}*/}
              {!isEmpty(fullTime) && ( //&& !isHidden
                <Fragment>
                  {(fullTime.h < 0 || fullTime.min < 0 || fullTime.sec < 0) && (
                    <div className="minus">-</div>
                  )}
                  <div className="number">
                    <span>{pad(Math.abs(+fullTime.h))}</span>
                    HRS
                  </div>
                  <span>:</span>
                  <div className="number">
                    <span>{pad(Math.abs(+fullTime.min))}</span>
                    MIN
                  </div>
                  <span>:</span>
                  <div className="number">
                    <span>{pad(Math.abs(+fullTime.sec))}</span>
                    SEC
                  </div>
                </Fragment>
              )}
              {/*{isHidden && (*/}
              {/*  <Message>*/}
              {/*    <div className="heading">Cannot start timer</div>*/}
              {/*    <div>*/}
              {/*      You cannot have multiple timer running simultaneously.*/}
              {/*      <br />*/}
              {/*      Please stop the timer at the other location first.*/}
              {/*    </div>*/}
              {/*  </Message>*/}
              {/*)}*/}
            </div>

            <div
              className={'start-stop'} //!isHidden ? 'start-stop' : 'start-stop hidden'
              onClick={
                // isHidden
                //   ? null
                //   :
                !isRunningTimer && !isEmpty(fullTime)
                  ? this.handlePopup
                  : !isRunningTimer
                  ? this.handleStart
                  : this.handleStop
              }
            >
              {!isRunningTimer && !isEmpty(fullTime) ? ( //&& !isHidden
                <span>Save</span>
              ) : !isRunningTimer ? ( //|| isHidden
                <span>Start</span>
              ) : (
                <span>Stop</span>
              )}
            </div>

            {!isRunningTimer &&
              !isEmpty(fullTime) && ( // && !isHidden
                <ControlButtons>
                  <div onClick={this.handleResetPopup}>Reset</div>

                  <div onClick={this.handleStart}>Continue</div>
                </ControlButtons>
              )}
          </Fragment>
        )}

        <ResetPopup
          open={openPopupReset}
          onClose={this.handleResetPopup}
          reset={this.handleReset}
        />

        <MinusTimePopup
          open={openMinusTimePopup}
          onClose={this.handleMinusTimePopup}
        />
      </WrapperAll>
    );
  }
}

const WrapperAll = styled.div`
  margin: 0 auto;
  .logo {
    margin-bottom: 20px;
  }
  .card {
    padding: 20px 30px;
    margin-bottom: 15px;
    display: flex;
  }
  .card.space-between {
    justify-content: space-around;
  }
  .card.transparent {
    background: none;
    box-shadow: none;
    justify-content: center;
    padding-bottom: 0;
  }
  .grey {
    color: #777;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.2;
  }
  p.heading .name {
    font-size: 18px;
    display: block;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .big-address {
    display: block;
  }
  .address,
  .big-address {
    color: #999999;
  }
  .date {
    color: #777;
    font-weight: 700;
  }
  .card .location,
  .card .clock {
    svg {
      margin-right: 15px;
    }
  }
  .time {
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }
  .time span {
    font-size: 14px;
    font-weight: 800;
    margin-left: 5px;
  }
  .timer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 7% 0;
    height: 178px;
  }
  .timer .number {
    text-align: center;
    font-size: 12px;
    color: #777;
  }
  .minus {
    font-family: Montserrat;
    font-weight: bold;
    line-height: 59px;
    font-size: 48px;
    padding-top: 4px;
  }
  .timer span {
    font-size: 48px;
    color: #333;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    display: block;
    padding: 0 7px;
  }
  .start-stop {
    cursor: pointer;
    width: 186px;
    height: 186px;
    background: #61933a;
    box-shadow: 0px 15px 40px rgba(97, 147, 58, 0.35);
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 30px;
    margin: 0 auto;
    text-transform: uppercase;
  }
  .start-stop.hidden {
    background: #cccccc;
    box-shadow: none;
    cursor: not-allowed;
  }
  .end-block {
    min-width: 115px;
  }
`;

const ControlButtons = styled.div`
  display: flex;
  max-width: 450px;
  margin: 0 auto;
  justify-content: space-between;
  text-transform: uppercase;
  margin-top: 23px;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #61933a;
  padding: 0 23px;
  div {
    cursor: pointer;
  }
`;

const Time = styled.div`
  position: relative;
  height: ${({ hidden, end }) => (hidden && end ? 'unset' : '72px')};
  display: flex;

  align-items: center;
  #start-time,
  #stop-time {
    cursor: ${({ notSet }) => (notSet ? 'not-allowed' : 'pointer')};
    position: absolute;
    opacity: ${({ hidden }) => (hidden ? '1' : '0')};
  }
  input {
    border: none;
    outline: none;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    width: 200px;
    background: transparent;
  }
  .active,
  .clock {
    display: ${({ hidden }) => (hidden ? 'none' : 'inherit')};
  }
  .active.hidden {
    color: #cccccc;
  }
  label {
    span {
      display: none;
    }
  }
`;

const TimeSet = styled.div`
  position: relative;
  display: ${({ hidden }) => (hidden ? 'block' : 'inherit')};

  #start-time,
  #stop-time {
    position: absolute;
    opacity: ${({ hidden }) => (hidden ? '1' : '0')};
    top: 0;
  }
  input {
    border: none;
    outline: none;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    width: 200px;
    background: transparent;
  }
  .time {
    position: relative;
    opacity: ${({ hidden }) => (hidden ? '0' : '1')};
  }
  label {
    span {
      display: none;
    }
  }
  .day {
    color: #999999;
    text-transform: uppercase;
  }
`;

// const Message = styled.div`
//   font-family: Roboto;
//   font-size: 14px;
//   line-height: 21px;
//   text-align: center;
//   color: #ff0000;
//   .heading {
//     font-weight: bold;
//   }
// `;

export default TimerPage;
