import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import ClockInactive from '../../assets/svgs/clock-inactive.svg';
import { ReactSVG } from 'react-svg';
import Spinner from '../../components/Spinner';
import { isEmpty } from 'lodash';
import InfoImg from '../../assets/svgs/info.svg';

let interval;
let timerTime = [];
const pad = (number) => {
  return number < 10 ? '0' + number : number;
};
const getDifferenceDate = (firstDate, secondDate) => {
  const ms = moment(firstDate, 'DD/MM/YYYY HH:mm:ss').diff(
    moment(secondDate, 'DD/MM/YYYY HH:mm:ss'),
  );
  const d = moment.duration(ms);
  const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
  const hours = +s.split(':')[0];
  const minutes = +s.split(':')[1];
  const seconds = +s.split(':')[2];

  return {
    h: hours,
    min: minutes,
    sec: seconds,
  };
};

const getDifFDateTillNow = (secondDate) => {
  const firstDate = new Date();
  let different = firstDate - secondDate;
  let minutes = Math.floor(different / 60000);
  let hours = Math.round(minutes / 60);
  let days = Math.round(hours / 24);

  return days;
};

class LocationsListWorkerPage extends React.Component {
  state = {
    showTimerOnIds: null,
    fullTimes: [],
    tab: 1,
  };
  componentDidMount() {
    const { getActiveJob, getLocations, getUser } = this.props;
    getUser();
    getActiveJob();
    getLocations();
  }
  componentDidUpdate(prevProps) {
    const { activeJob, listLocations, listAllLocations } = this.props;
    if (
      (activeJob.length &&
        ((!prevProps.listLocations.length && listLocations.length) ||
          (!prevProps.listAllLocations.length && listAllLocations.length))) ||
      (!prevProps.activeJob?.length &&
        activeJob.length &&
        (listLocations.length || listAllLocations.length))
    ) {
      let locationsActive = [];
      if (!prevProps.listLocations.length && listLocations.length) {
        // locationActive = listLocations.find(
        //   (location) => location && location.id === activeJob.locationId,
        // );

        locationsActive = listLocations.filter((location) =>
          activeJob.some(
            (activeJob) => location && location.id === activeJob.locationId,
          ),
        );
      } else {
        // locationActive = listAllLocations.find(
        //   (location) => location && location.id === activeJob.locationId,
        // );

        locationsActive = listAllLocations.filter((location) =>
          activeJob.some(
            (activeJob) => location && location.id === activeJob.locationId,
          ),
        );
      }
      if (locationsActive.length) {
        this.setState({ showTimerOnIds: locationsActive }, () => {
          locationsActive.map((location) => {
            let activeLocation = activeJob.find(
              (job) => job.locationId === location.id,
            );

            let isTimerRunning = !Boolean(activeLocation.endDate);

            if (activeLocation.startDate) {
              if (isTimerRunning) {
                const nowDate = new Date();

                const difference = getDifferenceDate(
                  nowDate,
                  new Date(activeLocation.startDate),
                );

                timerTime.push({
                  id: location.id,
                  time:
                    difference.h * 60 * 60 +
                    difference.min * 60 +
                    difference.sec,
                });
                // timerTime =
                //   difference.h * 60 * 60 + difference.min * 60 + difference.sec;
                this.incrementTimer(location.id);

                this.handleStart(location.id);
              } else {
                let activeLocation = activeJob.find(
                  (job) => job.locationId === location.id,
                );

                const difference = getDifferenceDate(
                  new Date(activeLocation.endDate),
                  new Date(activeLocation.startDate),
                );
                timerTime.push({
                  id: location.id,
                  time:
                    difference.h * 60 * 60 +
                    difference.min * 60 +
                    difference.sec,
                });
                // timerTime =
                //   difference.h * 60 * 60 + difference.min * 60 + difference.sec;
                this.incrementTimer(location.id);
              }
            }
          });
        });
      }
    }
  }

  incrementTimer = () => {
    const { activeJob } = this.props;
    let fullTimes = [...this.state.fullTimes];
    timerTime.forEach((time) => {
      const currentJob = activeJob.find((job) => job.locationId === time.id);
      if (!currentJob.endDate) {
        time.time++;
      }
      const seconds = Math.floor(time.time % 60);
      const minutes = Math.floor((time.time / 60) % 60);
      const hours = Math.floor(time.time / (60 * 60));

      const indexChange = this.state.fullTimes.findIndex(
        (fullTime) => fullTime.locationId === time.id,
      );

      if (indexChange !== -1) {
        fullTimes[indexChange] = {
          locationId: time.id,
          h: pad(hours),
          min: pad(minutes),
          sec: pad(seconds),
        };
      } else {
        fullTimes.push({
          locationId: time.id,
          h: pad(hours),
          min: pad(minutes),
          sec: pad(seconds),
        });
      }
    });

    this.setState({
      fullTimes: fullTimes,
    });
    // let time = timerTime.find((timer) => timer.id === locationId); // ++;
    // const seconds = Math.floor(time.time % 60);
    // const minutes = Math.floor((time.time / 60) % 60);
    // const hours = Math.floor((time.time / (60 * 60)) % 24);
  };

  handleStart = () => {
    clearInterval(interval);
    interval = setInterval(this.incrementTimer, 1000, { noDelay: true }); //locationId
  };

  handleChangeTab = (number) => {
    const { getLocations, getAllLocations } = this.props;
    this.setState({ tab: number }, () => {
      if (number === 1) {
        getLocations();
      } else if (number === 2) {
        getAllLocations();
      }
    });
  };

  componentWillUnmount() {
    timerTime = [];
    clearInterval(interval);
  }

  render() {
    const {
      history,
      isLoadingActive,
      isLoading,
      listLocations,
      listAllLocations,
      user,
      isLoadingUser,
    } = this.props;
    const { showTimerOnIds, fullTimes, tab } = this.state;

    const arrLocations = tab === 1 ? listLocations : listAllLocations;

    return (
      <Wrapper>
        {!isLoadingUser && user?.seesAllLocations && (
          <div className="tabs">
            <div
              className={tab === 1 && 'active'}
              onClick={() => this.handleChangeTab(1)}
            >
              Assigned Locations
            </div>
            <div
              className={tab === 2 && 'active'}
              onClick={() => this.handleChangeTab(2)}
            >
              All Locations
            </div>
          </div>
        )}

        {isLoadingActive || isLoading ? (
          <Spinner />
        ) : (
          <Fragment>
            {arrLocations.map((location, index) => {
              let difference;
              let diffDate;

              if (
                location.jobs.length &&
                !isEmpty(location.jobs[0]) &&
                location.jobs[0].startDate &&
                location.jobs[0].endDate
              ) {
                difference = getDifferenceDate(
                  new Date(location.jobs[0].endDate),
                  new Date(location.jobs[0].startDate),
                );
                diffDate = getDifFDateTillNow(
                  new Date(location.jobs[0].endDate),
                );
              }

              const timer = fullTimes.find(
                (time) => time.locationId === location.id,
              );

              return (
                <div key={index} className="card">
                  <p className="heading">
                    <div
                      onClick={() => {
                        history.push(`/timer/${location.id}`);
                      }}
                    >
                      <span>{location.name} </span>
                      {location.address}
                      {location.city ? ',' : ''} {location.city}
                      {location.province ? ',' : ''} {location.province}
                      {location.postalCode}
                      {showTimerOnIds &&
                        showTimerOnIds.find(
                          (timer) => timer.id === location.id,
                        ) &&
                        timer && (
                          <span
                            className={`${
                              location.img.length || location.comment
                                ? 'timer left'
                                : 'timer'
                            }`}
                          >
                            <Fragment>
                              <span>{timer.h}</span>
                              <span>:</span>
                              <span>{timer.min}</span>
                              <span>:</span>
                              <span>{timer.sec}</span>
                            </Fragment>
                          </span>
                        )}
                    </div>
                    {(location.img.length || location.comment) && (
                      <span
                        className="info"
                        onClick={(e) => {
                          history.push(`location/${location.id}`);
                        }}
                      >
                        <ReactSVG src={InfoImg} />
                      </span>
                    )}
                  </p>
                  <div className="flex-row">
                    {showTimerOnIds !== location.id && difference && (
                      <Fragment>
                        <ReactSVG
                          className="clock"
                          src={ClockInactive}
                          wrapper="span"
                        />
                        <span className="grey">
                          <strong>
                            <span>{difference.h}</span>h{' '}
                            <span>{difference.min}</span>m{' '}
                          </strong>
                          - {diffDate > 1 ? diffDate : ''}
                          {diffDate > 1 ? ` days ago` : `Today`}
                        </span>
                      </Fragment>
                    )}
                  </div>
                </div>
              );
            })}
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .logo {
    margin-bottom: 20px;
  }
  .card {
    padding: 20px 30px;
    margin-bottom: 15px;
  }
  .grey {
    color: #777;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 10px;
    position: relative;
  }
  p.heading {
    span {
      font-size: 18px;
      display: block;
      font-weight: 700;
      margin-bottom: 5px;
    }
    .timer {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      background: #61933a;
      border-radius: 50px;
      padding: 4px 10px;
      color: #fff;
      span {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .timer.left {
      right: 40px;
    }
  }

  .info {
    display: flex;
    position: absolute;
    top: -20px;
    right: 0;
    padding: 20px 0px 34px 4px;
  }
  .tabs {
    width: 100%;
    display: flex;
    font-size: 18px;
    font-family: 'Montserrat', sans-sans-serif;
    margin: 20px 0 30px;
    color: #777;
    font-weight: 700;
    justify-content: center;
    position: relative;
  }
  .tabs > div:first-child {
    margin-right: 60px;
  }
  .tabs .active {
    color: #333;
  }
  .tabs .active::after {
    content: '';
    height: 2px;
    width: 100%;
    background: #61933a;
    display: block;
    margin-top: 2px;
  }
  .clock {
    position: relative;
    top: -1px;
    margin-right: 7px;
  }
`;
const WrapTabs = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;
const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 76px 40px 28px 40px;
  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #777777;
    margin: 0;
    cursor: pointer;
  }
  .active {
    color: #333333;
    border-bottom: 2px solid #61933a;
    padding-bottom: 5px;
  }
`;

export default LocationsListWorkerPage;
