import { takeEvery, putResolve, actionChannel } from 'redux-saga/effects';
import { getRequestKey } from '../../utils/request';

const getMeta = (data = {}) => {
  const meta = { thunk: true, ...data };
  if (data.request) {
    meta.request.key = getRequestKey(data.request.params);
  }

  return meta;
};

function* apiWatch({ payload, meta }) {
  const [typeRequest] = payload.actions;
  yield typeRequest;
  try {
    yield putResolve({ payload, type: typeRequest, meta: getMeta(meta) });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('sagas apiWatch rejected: ', e);
  }
}

function* api() {
  const requestChan = yield actionChannel('API');
  yield takeEvery(requestChan, apiWatch);
}

export default api;
