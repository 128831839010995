import { connect } from 'react-redux';
import ProfilePage from './ProfilePage';
import { getUser } from '../../store/usersStore/actions';
import { userLogout } from '../../store/usersStore/actions';

const mapStateToProps = ({ usersStore: { user } }) => ({
  user,
});

const mapDispatchToProps = { getUser, userLogout };

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
