import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import LogoImg from '../../assets/images/logo.png';
import Form from './Form';
import { isEmpty } from 'lodash';

class LoginPage extends React.Component {
  state = {
    errorInForm: false,
  };
  componentDidUpdate(prevProps) {
    const { error, token, getUser } = this.props;

    if (!isEmpty(token) && isEmpty(prevProps.token)) {
      getUser();
    }

    if (!prevProps.error && error) {
      this.setState({ errorInForm: true });
    }
  }

  render() {
    const { loginUser, isLoading } = this.props;
    const { errorInForm } = this.state;

    return (
      <div className="container">
        <div className="logo">
          <img src={LogoImg} />
        </div>
        <Wrap className="card login">
          <p className="card-title">LOGIN</p>
          <Form
            loginUser={loginUser}
            isLoading={isLoading}
            errorInForm={errorInForm}
          />
        </Wrap>
      </div>
    );
  }
}
const Wrap = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;

export default withRouter(LoginPage);
