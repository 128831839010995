import 'sanitize.css/sanitize.css';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { store, persistor, history } from './store/store';
import './index.css';
import App from './App';

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App t={document.querySelector('#root')} />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.querySelector('#root'),
);
