import * as types from './constants';
import { removeToken } from '../../services/api/storage';

const initialState = {
  error: false,
  isLoading: false,
  user: null,
  logout: false,
  isLoadingUser: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_USER_REQUEST:
      return { ...state, isLoading: true, isLoadingUser: true };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isLoadingUser: false,
      };
    case types.GET_USER_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.USER_LOGOUT:
      removeToken();
      return initialState;

    default:
      return state;
  }
};
