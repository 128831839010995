import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LogoImg from '../../assets/images/logo.png';

class Header extends PureComponent {
  render() {
    return (
      <div className="logo">
        <img src={LogoImg} />
      </div>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
