import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import { loginUser } from '../../store/accountStore/actions';
import { getUser } from '../../store/usersStore/actions';

const mapStateToProps = ({ accountStore: { error, isLoading, token } }) => ({
  error,
  token,
  isLoading,
});

const mapDispatchToProps = {
  loginUser,
  getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
