import React, { Fragment } from 'react';
import styled from 'styled-components';
import Spinner from '../../components/Spinner';

class ProfilePage extends React.Component {
  componentDidMount() {
    this.props.getUser();
  }

  render() {
    const { user, userLogout } = this.props;

    return (
      <Wrapper>
        {!user ? (
          <Spinner />
        ) : (
          <Fragment>
            <p className="name">
              {user.name} {user.lastName}
            </p>
            <div>
              <button onClick={userLogout}>LOGOUT</button>
            </div>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  div {
    display: flex;
    justify-content: center;
  }
  .name {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    display: flex;
    justify-content: center;
    margin-top: 105px;
    margin-bottom: 44px;
  }
  button {
    width: 294px;
    cursor: pointer;
    background: #61933a;
    border-radius: 5px;
    height: 40px;
    color: #fff;
    border: none;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
  }
`;

export default ProfilePage;
