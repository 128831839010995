export const GET_LIST_LOCATIONS_REQUEST = 'GET_LIST_LOCATIONS_REQUEST';
export const GET_LIST_LOCATIONS_SUCCESS = 'GET_LIST_LOCATIONS_SUCCESS';
export const GET_LIST_LOCATIONS_FAIL = 'GET_LIST_LOCATIONS_FAIL';

export const GET_ALL_LOCATIONS_REQUEST = 'GET_ALL_LOCATIONS_REQUEST';
export const GET_ALL_LOCATIONS_SUCCESS = 'GET_ALL_LOCATIONS_SUCCESS';
export const GET_ALL_LOCATIONS_FAIL = 'GET_ALL_LOCATIONS_FAIL';

export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';

export const DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';
