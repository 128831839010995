import * as types from './constants';

export const getLocations = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/locations' },
      actions: [
        types.GET_LIST_LOCATIONS_REQUEST,
        types.GET_LIST_LOCATIONS_SUCCESS,
        types.GET_LIST_LOCATIONS_FAIL,
      ],
    },
  };
};

export const getLocationById = (locationsId) => {
  return {
    type: 'API',
    payload: {
      request: { url: `/v1/locations/${locationsId}` },
      actions: [
        types.GET_LOCATION_REQUEST,
        types.GET_LOCATION_SUCCESS,
        types.GET_LOCATION_FAIL,
      ],
    },
  };
};

export const getAllLocations = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/locations/listing' },
      actions: [
        types.GET_ALL_LOCATIONS_REQUEST,
        types.GET_ALL_LOCATIONS_SUCCESS,
        types.GET_ALL_LOCATIONS_FAIL,
      ],
    },
  };
};

export const deleteLocation = (id) => ({
  type: 'API',
  payload: {
    request: {
      method: 'DELETE',
      url: `/v1/locations`,
      json: { id: id },
    },
    actions: [
      types.DELETE_LOCATION_REQUEST,
      types.DELETE_LOCATION_SUCCESS,
      types.DELETE_LOCATION_FAIL,
    ],
  },
});
