import * as types from './constants';

export const getJobs = () => ({
  type: 'API',
  payload: {
    request: {
      url: `/v1/jobs`,
    },
    actions: [
      types.GET_JOBS_REQUEST,
      types.GET_JOBS_SUCCESS,
      types.GET_JOBS_FAIL,
    ],
  },
});

export const getActiveJob = () => ({
  type: 'API',
  payload: {
    request: {
      url: `/v1/jobs/active`,
    },
    actions: [
      types.GET_ACTIVE_JOB_REQUEST,
      types.GET_ACTIVE_JOB_SUCCESS,
      types.GET_ACTIVE_JOB_FAIL,
    ],
  },
});

export const createJob = ({ locationId, payload }) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'POST',
        url: `/v1/locations/${locationId}/jobs`,
        json: { startDate: payload.startDate.slice(0, -5) },
      },
      actions: [
        types.CREATE_JOB_REQUEST,
        types.CREATE_JOB_SUCCESS,
        types.CREATE_JOB_FAIL,
      ],
    },
  };
};

export const updateJob = ({ jobId, payload }) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'PATCH',
        url: `/v1/jobs/${jobId}`,
        json: {
          startDate: payload.startDate ? payload.startDate.slice(0, -5) : null,
          endDate: payload.endDate ? payload.endDate.slice(0, -5) : null,
        },
      },
      actions: [
        types.UPDATE_JOB_REQUEST,
        types.UPDATE_JOB_SUCCESS,
        types.UPDATE_JOB_FAIL,
      ],
    },
  };
};

export const createJobTasks = ({ jobId, payload }) => ({
  type: 'API',
  payload: {
    request: {
      method: 'PATCH',
      url: `/v1/jobs/${jobId}/tasks`,
      json: payload,
    },
    actions: [
      types.ADD_JOB_TASKS_REQUEST,
      types.ADD_JOB_TASKS_SUCCESS,
      types.ADD_JOB_TASKS_FAIL,
    ],
  },
});

export const deleteJob = (jobId) => ({
  type: 'API',
  payload: {
    request: {
      method: 'DELETE',
      url: `/v1/jobs/${jobId}`,
    },
    actions: [
      types.DELETE_JOB_REQUEST,
      types.DELETE_JOB_SUCCESS,
      types.DELETE_JOB_FAIL,
    ],
  },
});

export const addNoteToJob = ({ jobId, comment }) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'POST',
        url: `/v1/jobs/${jobId}/comment`,
        json: { comment: comment },
      },
      actions: [
        types.ADD_NOTE_TO_JOB_REQUEST,
        types.ADD_NOTE_TO_JOB_SUCCESS,
        types.ADD_NOTE_TO_JOB_FAIL,
      ],
    },
  };
};

export const joinJobImg = (jobId, payload) => {
  return {
    type: 'API',
    payload: {
      request: {
        method: 'POST',
        url: `/v1/jobs/${jobId}/image`,
        json: payload,
      },
      actions: [
        types.JOIN_JOB_TO_IMG_REQUEST,
        types.JOIN_JOB_TO_IMG_SUCCESS,
        types.JOIN_JOB_TO_IMG_FAIL,
      ],
    },
  };
};
