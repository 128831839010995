import React from 'react';
import { Link } from 'react-router-dom';

export const SentDialog = () => {
  return (
    <div>
      <p>
        <p>A password reset email has been sent. Check your inbox.</p>
      </p>
      <p className="links center">
        <Link to="/login">Sign In</Link>
      </p>
    </div>
  );
};
