import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

const ImagePopup = ({ open, src, onClose }) => (
  <StyledDialog open={open} onClose={() => onClose(false)}>
    <Wrapper>
      <Content>
        <img src={src} alt="" />
      </Content>
      <ButtonsWrap>
        <Button onClick={() => onClose(false)}>CLOSE</Button>
      </ButtonsWrap>
    </Wrapper>
  </StyledDialog>
);

const Wrapper = styled.div`
  padding: 30px 40px;
  min-width: 200px !important;

  @media (max-width: 550px) {
    padding: 20px 15px;
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #61933a;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 15px;
`;

const Content = styled.div`
  img {
    width: 100%;
    max-height: 600px;
    object-fit: contain;
  }
`;

const StyledDialog = styled(Dialog)`
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

export default ImagePopup;
