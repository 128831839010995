import { connect } from 'react-redux';
import LocationInfoPage from './LocationInfoPage';
import { getLocationById } from '../../store/locationsStore/actions';

const mapStateToProps = ({ locationsStore: { location, isLoading } }) => ({
  location,
  isLoading,
});

const mapDispatchToProps = { getLocationById };

export default connect(mapStateToProps, mapDispatchToProps)(LocationInfoPage);
