import Alert from 'react-s-alert';
import styled from 'styled-components';
import 'react-s-alert/dist/s-alert-default.css';

const StyledAlert = styled(Alert).attrs({
  stack: { limit: 2 },
  position: 'bottom-left',
})``;

export default StyledAlert;
