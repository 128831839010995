import React from 'react';
import styled, { css } from 'styled-components';
import DeleteIcon from '../../assets/svgs/trash-cricle.svg';
import SvgIcon from '../../components/SvgIcon';

const FilePreview = ({
  file,
  draggable,
  small,
  multiple,
  onDragStart,
  onDragOver,
  onDrop,
  onDelete,
  className = '',
}) => {
  const show = small === 'true' || Boolean(multiple);
  const src = file && file.length > 0 ? (multiple ? file : file[0]) : '';
  return (
    <Wrap small={small} multi={multiple}>
      <HoverWrap show={show} className={className}>
        <ImageWrap
          small={small}
          className="box"
          draggable={draggable}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <img src={src} alt="file preview" />
          <Shadow className="shadow-back" />
        </ImageWrap>
        <DeleteWrap show={show} onClick={onDelete} className="delete-btn">
          <SvgIcon src={DeleteIcon} />
        </DeleteWrap>
      </HoverWrap>
    </Wrap>
  );
};

const HoverWrap = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;

  .shadow-back,
  .delete-btn {
    display: flex;
    transition: 0.3s;
  }
`;

const Shadow = styled.div`
  top: 0px;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
`;

const DeleteWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin: 5px auto 0px;
  transition: 0.3s;
  border-radius: 3px;

  &:hover {
    background-color: #fef7ef;
  }

  top: -7px;
  right: -4px;
  width: 30px;
  height: 30px;
  display: none;
  position: absolute;

  svg {
    width: 30px;
    height: 30px;
    transition: 0.1s;
    position: absolute;
    right: 8px;
    top: 6px;

    path {
      stroke: #fff;
    }
  }

  &:hover {
    background-color: transparent !important;

    svg {
      transform: scale(1.1);
    }
  }
`;

const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  img,
  svg {
    margin: 0 auto;
    height: 88px !important;
    width: 88px !important;
    max-width: 88px !important;
  }
`;

const Wrap = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: -20px;
  border-radius: 3px;
  overflow: hidden;
  height: ${({ small }) => (small === 'true' ? 60 : 220)}px;

  @media (max-width: 1200px) {
    height: ${({ small }) => (small === 'true' ? 60 : 190)}px;
  }

  ${({ multi }) =>
    multi &&
    css`
      height: ${({ small }) => (small === 'true' ? 60 : 88)}px;
      margin: 0 5px 20px;

      @media (max-width: 1200px) {
        height: 150px;
      }

      @media (max-width: 768px) {
        width: calc(50% - 16px);
      }
    `}
`;

export default FilePreview;
