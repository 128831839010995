import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ user, location, ...rest }) {
  if (!user) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { from: { pathname: '/' } } }}
      />
    );
  }

  return <Route location={location} {...rest} />;
}

const mapStateToProps = ({ dataStore: { user } }) => ({ user });
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
