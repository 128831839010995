import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Header from '../../components/Header';
import FooterMenu from '../../components/FooterMenu';
import { SidebarLayoutContext } from './context';

class SidebarLayout extends React.Component {
  static contextType = SidebarLayoutContext;

  render() {
    const { children } = this.props;

    const {} = this.context;

    return (
      <Fragment>
        <FooterMenu />
        <div className="container">
          <Header />
          <div className="content-wrapper">
            {
              true ? (
                <Switch>
                  <Route
                    exact
                    path={[
                      '/',
                      '/timer/:locationId',
                      '/list-tasks',
                      '/profile',
                    ]}
                  >
                    {children}
                  </Route>
                </Switch>
              ) : null
              // <Spinner />
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ usersStore: { identity } }) => ({
  identity,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarLayout);
