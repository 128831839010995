import * as types from './constants';

export const loginUser = (payload) => ({
  type: 'API',
  payload: {
    request: {
      method: 'POST',
      url: '/v1/user/login',
      json: payload,
    },
    actions: [
      types.USER_LOGIN_REQUEST,
      types.USER_LOGIN_SUCCESS,
      types.USER_LOGIN_FAIL,
    ],
  },
});

export const forgotPassword = (payload) => ({
  type: 'API',
  payload: {
    request: {
      method: 'POST',
      url: '/v1/user/forgot',
      json: payload,
    },
    actions: [
      types.RESET_PASSWORD_REQUEST,
      types.RESET_PASSWORD_SUCCESS,
      types.RESET_PASSWORD_FAIL,
    ],
  },
});

export const resetPassword = (payload) => ({
  type: 'API',
  payload: {
    request: {
      method: 'POST',
      url: '/v1/user/pass',
      json: payload,
    },
    actions: [
      types.CHANGE_PASSWORD_REQUEST,
      types.CHANGE_PASSWORD_SUCCESS,
      types.CHANGE_PASSWORD_FAIL,
    ],
  },
});
