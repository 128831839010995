import React from 'react';
import styled, { css } from 'styled-components';
import DeleteIcon from '../../assets/svgs/trash-cricle.svg';
import SvgIcon from '../../components/SvgIcon';

const FilePreview = ({
  src,
  small,
  multiple,
  onDragStart,
  onDragOver,
  onDrop,
  handleImgPopup,
  className = '',
}) => {
  const show = small === 'true' || Boolean(multiple);
  return (
    <Wrap small={small} multi={multiple}>
      <HoverWrap show={show} className={className}>
        <ImageWrap
          small={small}
          className="box"
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onClick={() => {
            handleImgPopup(src);
          }}
        >
          <img src={src} alt="file preview" />
          <Shadow className="shadow-back" />
        </ImageWrap>
      </HoverWrap>
    </Wrap>
  );
};

const HoverWrap = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100%;

  .shadow-back,
  .delete-btn {
    display: flex;
    transition: 0.3s;
  }
`;

const Shadow = styled.div`
  top: 0px;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
`;

const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  img,
  svg {
    margin: 0 auto;
    height: 80px !important;
    width: 80px !important;
    max-width: 80px !important;
  }
`;

const Wrap = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  border-radius: 3px;
  overflow: hidden;
`;

export default FilePreview;
