import { takeEvery, all } from 'redux-saga/effects';
import * as types from './constants';
import { handleRequest } from '../sagas/request';

export default all([
  takeEvery(types.GET_LIST_LOCATIONS_REQUEST, handleRequest),
  takeEvery(types.GET_ALL_LOCATIONS_REQUEST, handleRequest),
  takeEvery(types.GET_LOCATION_REQUEST, handleRequest),
  takeEvery(types.DELETE_LOCATION_REQUEST, handleRequest),
]);
