import * as types from './constants';

export const getUser = () => {
  return {
    type: 'API',
    payload: {
      request: { url: '/v1/user' },
      actions: [
        types.GET_USER_REQUEST,
        types.GET_USER_SUCCESS,
        types.GET_USER_FAIL,
      ],
    },
  };
};

export const userLogout = () => ({ type: types.USER_LOGOUT });
