import React, { Fragment } from 'react';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import ClockInactive from '../../assets/svgs/clock-inactive.svg';
import { formatDate, formatTimeLowerCase } from '../../utils/formatDate';
import { TASK_STATUS_ID_YES } from '../../utils/tasks';
import Spinner from '../../components/Spinner';
import GreenNotes from '../../assets/svgs/green-note.svg';
import GreenImg from '../../assets/svgs/green-img.svg';

const ROADS_DEFAULT = 'Roads';
const SIDEWALKS_DEFAULT = 'Sidewalks';
const OTHER_DEFAULT = 'Other';

const isDone = (task) =>
  task.JobsTasks && task.JobsTasks.taskStatusId === TASK_STATUS_ID_YES;

class JobsListWorkerPage extends React.Component {
  componentDidMount() {
    this.props.getJobs();
  }

  render() {
    const { jobByDate, isLoading } = this.props;
    return (
      <Wrapper>
        {isLoading && !jobByDate.length && <Spinner />}
        {jobByDate.map(({ date, jobs }) => (
          <Fragment key={date}>
            <h2>{formatDate(date)}</h2>
            {jobs.map((job) => {
              let rodTasks = [];
              let sidewalkTasks = [];
              let otherTasks = [];
              if (job.tasks.length) {
                rodTasks = job.tasks.filter(
                  (task) => task.place.name === ROADS_DEFAULT,
                );
                sidewalkTasks = job.tasks.filter(
                  (task) => task.place.name === SIDEWALKS_DEFAULT,
                );
                otherTasks = job.tasks
                  .filter((task) => task.place.name === OTHER_DEFAULT)
                  .sort((taskOne, taskTwo) => taskOne.id - taskTwo.id);
              }
              return (
                <div key={job.id} className="card">
                  <p className="heading">
                    <div className="main">{job.location.name}</div>
                    <span className="full-address">
                      {job.location.address}
                      {job.location.city ? ',' : ''} {job.location.city}
                      {job.location.province ? ',' : ''} {job.location.province}
                      {job.location.postalCode}
                    </span>
                    <WrapSvgs>
                      {job.comment && <ReactSVG src={GreenNotes} />}
                      {Boolean(job.img.length) && <ReactSVG src={GreenImg} />}
                    </WrapSvgs>
                  </p>
                  <div className="flex-row grey">
                    <strong>
                      <ReactSVG
                        className="clock"
                        src={ClockInactive}
                        wrapper="span"
                      />
                      {formatTimeLowerCase(job.startDate)} -{' '}
                      {formatTimeLowerCase(job.endDate)}
                    </strong>
                    <div className="services">
                      <div className="roads">
                        {rodTasks.map((task) => (
                          <div
                            key={task.id}
                            className={clsx('green', {
                              active: isDone(task),
                            })}
                          >
                            {task.name.slice(0, 1)}
                          </div>
                        ))}
                      </div>
                      <div className="sidewalk">
                        {sidewalkTasks.map((task) => (
                          <div
                            key={task.id}
                            className={clsx('blue', {
                              active: isDone(task),
                            })}
                          >
                            {task.name.slice(0, 1)}
                          </div>
                        ))}
                      </div>
                      <div className="other">
                        {otherTasks.map((task) => (
                          <div
                            key={task.id}
                            className={clsx('brown', {
                              active: isDone(task),
                            })}
                          >
                            {task.name.slice(0, 1)}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Fragment>
        ))}
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  .logo {
    margin-bottom: 20px;
  }
  .card {
    padding: 20px 30px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .grey {
    color: #777;
  }
  p.heading {
    margin-top: 0;
    margin-bottom: 10px;
  }
  p.heading .main {
    font-size: 18px;
    display: block;
    font-weight: 700;
    margin-bottom: 5px;
  }
  p.heading .full-address {
    color: #777;
  }
  .clock {
    position: relative;
    top: -1px;
    margin-right: 7px;
  }
  .services {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 6px;
  }
  .services > div {
    display: flex;
  }
  .services > div > div {
    width: 16px;
    line-height: 16px;
    text-align: center;
    height: 16px;
    border-radius: 2px;
    background: #ccc;
    margin-left: 5px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
  .services > div {
    margin-left: 15px;
  }
  .services .green.active {
    background: #63933a;
  }
  .services .blue.active {
    background: #3c589f;
  }
  .services .brown.active {
    background: #795548;
  }
  .flex-row {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    p.heading {
      margin-bottom: 0;
    }
  }
`;

const WrapSvgs = styled.div`
  display: flex;
  svg {
    margin-top: 8px;
    margin-right: 8px;
  }
`;

export default JobsListWorkerPage;
