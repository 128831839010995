import { connect } from 'react-redux';
import TimerPage from './TimerPage';
import {
  createJob,
  getActiveJob,
  updateJob,
  getJobs,
  createJobTasks,
  deleteJob,
  addNoteToJob,
  joinJobImg,
} from '../../store/jobsStore/actions';
import { getLocationById } from '../../store/locationsStore/actions';

const mapStateToProps = ({
  jobsStore: {
    activeJob,
    jobs,
    tasksCreated,
    isLoadingActive,
    isLoading,
    error,
  },
  locationsStore: { location },
}) => ({
  location,
  isLoading,
  activeJob,
  error,
  isLoadingActive,
  tasksCreated,
  jobs,
});

const mapDispatchToProps = {
  getActiveJob,
  createJobTasks,
  createJob,
  updateJob,
  getJobs,
  getLocationById,
  deleteJob,
  addNoteToJob,
  joinJobImg,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimerPage);
