import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ReactSVG } from 'react-svg';
import LocationActiveImg from '../../assets/svgs/location-active.svg';
import LocationInactiveImg from '../../assets/svgs/location-inactive.svg';
import TimerActiveImg from '../../assets/svgs/timer-active.svg';
import TimerInactiveImg from '../../assets/svgs/timer-inactive.svg';
import PersonActiveImg from '../../assets/svgs/preson-active.svg';
import PersonInactiveImg from '../../assets/svgs/preson-inactive.svg';

class FooterMenu extends PureComponent {
  render() {
    const {
      location: { pathname },
    } = this.props;
    return (
      <Wrapper>
        <ul>
          <li>
            <Link to="/">
              {pathname === '/' || pathname === '/timer' ? (
                <ReactSVG src={LocationActiveImg} />
              ) : (
                <ReactSVG src={LocationInactiveImg} />
              )}
            </Link>
          </li>
          <li>
            <Link to="/list-tasks">
              {pathname === '/list-tasks' ? (
                <ReactSVG src={TimerActiveImg} />
              ) : (
                <ReactSVG src={TimerInactiveImg} />
              )}
            </Link>
          </li>
          <li>
            <Link to="/profile">
              {pathname === '/profile' ? (
                <ReactSVG src={PersonActiveImg} />
              ) : (
                <ReactSVG src={PersonInactiveImg} />
              )}
            </Link>
          </li>
        </ul>
      </Wrapper>
    );
  }
}

const Wrapper = styled.nav`
  li {
    width: 33%;
    display: flex;
    justify-content: center;
    height: 72%;
    align-items: center;
    cursor: pointer;
  }
`;

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FooterMenu),
);
