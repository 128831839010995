/* eslint-disable no-console */
import { call, put } from 'redux-saga/effects';
import { ValidationError } from 'yup';
import { normalize } from 'normalizr';
import request from '../../services/api/request';
// import { showAlertError } from '../../utils/template';
import { USER_LOGOUT } from '../usersStore/constants';

export function* handleError(err, { meta, type }) {
  console.log('sagas request handleError: ', err);
  let error = err;
  if (err instanceof ValidationError) {
    error = new Error('API request failed');
    // showAlertError(error.message);
  } else if (error.status === 401) {
    yield put({ type: USER_LOGOUT });
  }

  yield put({
    type,
    meta,
    error: true,
    payload: error,
  });
}

export function* handleResponse(response, { meta, type, shape, schema, id }) {
  let data = response;
  if (shape) {
    data = shape.validateSync(response);
  }

  if (schema) {
    data = normalize(data, schema);
  }

  yield put({
    type,
    meta,
    id,
    payload: data,
  });
}

export function* handleRequest({ payload, meta }) {
  const { actions, response = {}, id } = payload;
  const [typeSuccess, typeFailed] = actions.slice(1);
  try {
    const res = yield call(request, payload.request);
    yield handleResponse(res, {
      ...response,
      id,
      meta,
      type: typeSuccess,
    });
  } catch (e) {
    yield handleError(e, { meta, type: typeFailed });
  }
}
