import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

const MinusTimePopup = ({ open, onClose }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <Wrapper>
        <p>
          The <b>End</b> time needs to be later than the <b>Start</b> time
        </p>
        <Button onClick={onClose} className="active">
          OK
        </Button>
      </Wrapper>
    </StyledDialog>
  );
};
const Wrapper = styled.div`
  padding: 20px 47px;
  min-width: 200px !important;
  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.1px;
  @media (max-width: 550px) {
    padding: 20px 30px;
  }
`;

const Button = styled.button`
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  width: 117px;
  height: 40px;
  border: 1px solid #61933a;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  background: #61933a;
  color: #fff;
  margin: 32px auto 0 auto;
`;

const StyledDialog = styled(Dialog)`
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

export default MinusTimePopup;
