import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

const ResetPopup = ({ open, onClose, reset }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <Wrapper>
        <h3>Confirm reset</h3>
        <p>Are you sure you want to reset?</p>
        <ButtonsWrap>
          <button onClick={onClose}>NO</button>
          <button onClick={reset} className="active">
            YES
          </button>
        </ButtonsWrap>
      </Wrapper>
    </StyledDialog>
  );
};
const Wrapper = styled.div`
  padding: 20px 47px;
  min-width: 200px !important;
  h3,
  p {
    margin: 0 auto;
    text-align: center;
  }
  p {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  @media (max-width: 550px) {
    padding: 20px 15px;
  }
`;

const ButtonsWrap = styled.div`
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  button {
    width: 117px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #61933a;
    color: #61933a;
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    &.active {
      background: #61933a;
      color: #fff;
      margin-left: 24px;
    }
  }
`;

const StyledDialog = styled(Dialog)`
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

export default ResetPopup;
