import React, { createContext, useState, useRef } from 'react';

export const SidebarLayoutContext = createContext(null);
export const SidebarLayoutProvider = ({ children }) => {
  const [header, setHeader] = useState(null);
  const [showReminder, setShowReminder] = useState(false);
  const headerRightRef = useRef();
  return (
    <SidebarLayoutContext.Provider
      value={{
        header,
        setHeader,
        showReminder,
        setShowReminder,
        headerRightRef,
      }}
    >
      {children}
    </SidebarLayoutContext.Provider>
  );
};
