import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

export default class Form extends React.Component {
  state = {
    values: null,
  };

  handleSubmit = (values) => {
    this.props.loginUser(values);
  };

  render() {
    const { isLoading, errorInForm } = this.props;
    return (
      <Formik
        initialValues={{
          password: '',
          email: '',
        }}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('The email address is invalid')
            .required('Email is required'),
          password: Yup.string()
            .min(8, 'must be at least 8 characters')
            .required('is required'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form noValidate="" onSubmit={handleSubmit}>
              {errorInForm && (
                <div className="login-error">
                  <span>
                    You’ve entered an incorrect email, password or both.
                  </span>
                </div>
              )}
              <div
                className={
                  errors.email && touched.email
                    ? 'form-row has-error'
                    : 'form-row'
                }
              >
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={values.email}
                  placeholder="example@site.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="form-error-message">{errors.email}</div>
              </div>
              <div
                className={
                  errors.password && touched.password
                    ? 'form-row split-input has-error'
                    : 'form-row split-input'
                }
              >
                <label htmlFor="password">Password</label>
                <input
                  value={values.password}
                  autoComplete="off"
                  autoCorrect="off"
                  id="password"
                  placeholder="••••••"
                  spellCheck="false"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="form-error-message">
                  Password {errors.password}
                </div>
              </div>

              <div className="form-row">
                <button type="submit" disabled={isLoading}>
                  SIGN IN
                </button>
              </div>
              <LinkP className="links center">
                <Link to="/forgot-password">Forgot Password?</Link>
              </LinkP>
            </form>
          );
        }}
      </Formik>
    );
  }
}

const LinkP = styled.p`
  a {
    text-decoration: none;
  }
`;
