import { connect } from 'react-redux';
import LocationsListWorkerPage from './LocationsListWorkerPage';
import {
  getLocations,
  getAllLocations,
} from '../../store/locationsStore/actions';
import { getUser } from '../../store/usersStore/actions';
import { getActiveJob } from '../../store/jobsStore/actions';

const mapStateToProps = ({
  locationsStore: { listLocations, isLoading, listAllLocations },
  jobsStore: { activeJob, isLoadingActive },
  usersStore: { user, isLoadingUser },
}) => ({
  isLoading,
  isLoadingUser,
  listLocations,
  listAllLocations,
  activeJob,
  isLoadingActive,
  user,
});

const mapDispatchToProps = {
  getUser,
  getLocations,
  getAllLocations,
  getActiveJob,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationsListWorkerPage);
