export const CREATE_JOB_REQUEST = 'CREATE_JOB_REQUEST';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAIL = 'CREATE_JOB_FAIL';

export const UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST';
export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAIL = 'UPDATE_JOB_FAIL';

export const DELETE_JOB_REQUEST = 'DELETE_JOB_REQUEST';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAIL = 'DELETE_JOB_FAIL';

export const GET_JOBS_REQUEST = 'GET_JOBS_REQUEST';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_FAIL = 'GET_JOBS_FAIL';

export const GET_ACTIVE_JOB_REQUEST = 'GET_ACTIVE_JOB_REQUEST';
export const GET_ACTIVE_JOB_SUCCESS = 'GET_ACTIVE_JOB_SUCCESS';
export const GET_ACTIVE_JOB_FAIL = 'GET_ACTIVE_JOB_FAIL';

export const ADD_JOB_TASKS_REQUEST = 'ADD_JOB_TASKS_REQUEST';
export const ADD_JOB_TASKS_SUCCESS = 'ADD_JOB_TASKS_SUCCESS';
export const ADD_JOB_TASKS_FAIL = 'ADD_JOB_TASKS_FAIL';

export const ADD_NOTE_TO_JOB_REQUEST = 'ADD_NOTE_TO_JOB_REQUEST';
export const ADD_NOTE_TO_JOB_SUCCESS = 'ADD_NOTE_TO_JOB_SUCCESS';
export const ADD_NOTE_TO_JOB_FAIL = 'ADD_NOTE_TO_JOB_FAIL';

export const JOIN_JOB_TO_IMG_REQUEST = 'JOIN_JOB_TO_IMG_REQUEST';
export const JOIN_JOB_TO_IMG_SUCCESS = 'JOIN_JOB_TO_IMG_SUCCESS';
export const JOIN_JOB_TO_IMG_FAIL = 'JOIN_JOB_TO_IMG_FAIL';
