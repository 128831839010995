import { takeEvery, all } from 'redux-saga/effects';
import * as types from './constants';
import { handleRequest } from '../sagas/request';

export default all([
  takeEvery(types.CREATE_JOB_REQUEST, handleRequest),
  takeEvery(types.UPDATE_JOB_REQUEST, handleRequest),
  takeEvery(types.DELETE_JOB_REQUEST, handleRequest),
  takeEvery(types.GET_JOBS_REQUEST, handleRequest),
  takeEvery(types.ADD_JOB_TASKS_REQUEST, handleRequest),
  takeEvery(types.GET_ACTIVE_JOB_REQUEST, handleRequest),
  takeEvery(types.ADD_NOTE_TO_JOB_REQUEST, handleRequest),
  takeEvery(types.JOIN_JOB_TO_IMG_REQUEST, handleRequest),
]);
