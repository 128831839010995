import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, BrowserRouter } from 'react-router-dom';
import Alert from 'react-s-alert';
import LoginPage from './pages/Login';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
import LocationsListWorkerPage from './pages/LocationsListWorker';
import LocationInfoPage from './pages/LocationInfo';
import SaveTimerPage from './pages/SaveTimer';
import TimerPage from './pages/Timer';
import ProfilePage from './pages/Profile';
import JobsListWorkerPage from './pages/JobsListWorkerPage';
import GuestRoute from './components/GuestRoute';
import PrivateRoute from './components/PrivateRoute';
import SidebarLayout from './layouts/SidebarLayout';
import { SidebarLayoutProvider } from './layouts/SidebarLayout/context';
import { getUser } from './store/usersStore/actions';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

class App extends React.Component {
  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.props.getUser();
    }
  }

  render() {
    return (
      <Fragment>
        <Alert stack={{ limit: 3 }} position={'bottom-left'} />
        <BrowserRouter>
          <Switch>
            <GuestRoute exact path="/login" component={LoginPage} />
            <GuestRoute
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <GuestRoute
              exact
              path="/forgot/password"
              component={ResetPasswordPage}
            />

            <PrivateRoute
              exact
              path="/timer/:locationId/save"
              component={SaveTimerPage}
            />

            <PrivateRoute
              exact
              path="/location/:locationId"
              component={LocationInfoPage}
            />

            <PrivateRoute
              exact
              path={['/', '/timer/:locationId', '/list-tasks', '/profile']}
            >
              <SidebarLayoutProvider>
                <SidebarLayout>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/"
                      component={LocationsListWorkerPage}
                    />
                    <PrivateRoute
                      exact
                      path="/timer/:locationId"
                      component={TimerPage}
                    />
                    <PrivateRoute
                      exact
                      path="/list-tasks"
                      component={JobsListWorkerPage}
                    />
                    <PrivateRoute
                      exact
                      path="/profile"
                      component={ProfilePage}
                    />
                  </Switch>
                </SidebarLayout>
              </SidebarLayoutProvider>
            </PrivateRoute>
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  dataStore: { user },
  usersStore: { isLoadingValidate },
}) => ({
  user,
  isLoadingValidate,
});

const mapDispatchToProps = {
  getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
