import * as types from './constants';

const initialState = {
  error: false,
  isLoading: false,
  listLocations: [],
  listAllLocations: [],
  location: {},
  logout: false,
  isLocationDeleted: false,
  pageSize: 12,
  currentPage: 1,
  totalElements: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LIST_LOCATIONS_REQUEST:
      return { ...state, listLocations: [], isLoading: true };
    case types.GET_LIST_LOCATIONS_SUCCESS:
      return {
        ...state,
        // pageSize: Math.ceil(payload.total / payload.limit),
        listLocations: payload.locations, //.data
        // currentPage: payload.page,
        totalElements: payload.total,
        isLoading: false,
      };
    case types.GET_LIST_LOCATIONS_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_ALL_LOCATIONS_REQUEST:
      return { ...state, listAllLocations: [], isLoading: true };
    case types.GET_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        // pageSize: Math.ceil(payload.total / payload.limit),
        listAllLocations: payload.locations, //.data
        // currentPage: payload.page,
        totalElements: payload.total,
        isLoading: false,
      };
    case types.GET_ALL_LOCATIONS_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.GET_LOCATION_REQUEST:
      return { ...state, isLoading: true };
    case types.GET_LOCATION_SUCCESS:
      return {
        ...state,
        location: payload,
        isLoading: false,
      };
    case types.GET_LOCATION_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.DELETE_LOCATION_REQUEST:
      return { ...state, isLocationDeleted: false, isLoading: true };
    case types.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        isLocationDeleted: true,
        isLoading: false,
      };
    case types.DELETE_LOCATION_FAIL:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
};
