import { all } from 'redux-saga/effects';
import api from './api';
import authSagas from '../usersStore/sagas';
import accountSagas from '../accountStore/sagas';
import dataSagas from '../dataStore/sagas';
import locationsSagas from '../locationsStore/sagas';
import jobsSagas from '../jobsStore/sagas';

export default function* rootSaga() {
  yield all([
    api(),
    authSagas,
    dataSagas,
    locationsSagas,
    accountSagas,
    jobsSagas,
  ]);
}
