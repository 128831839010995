import React from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../Spinner';
import Camera from '../../assets/svgs/add-image-icons.svg';

const DropZone = ({
  small,
  value,
  inputId,
  multiple,
  isLoading,
  inputProps,
}) => {
  const width = multiple && value.length > 0;
  return (
    <Content small={small} multi={width} capture="camera">
      <input {...inputProps} id={inputId} />
      {isLoading ? (
        <Loader>
          <Spinner size={small === 'false' ? 40 : 20} />
        </Loader>
      ) : (
        <img src={Camera} alt="drop" />
      )}
    </Content>
  );
};

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ small }) => (small === 'true' ? 24 : 48)}px;
  height: ${({ small }) => (small === 'true' ? 21 : 42)}px;
`;

const Content = styled.div`
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 5px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  min-width: 88px;
  min-height: 88px;

  img {
    width: ${({ small }) => (small === 'true' ? 24 : 48)}px;
    height: ${({ small }) => (small === 'true' ? 21 : 42)}px;
  }

  ${({ multi }) =>
    multi &&
    css`
      margin: 0 8px;
      width: 88px;
    `}
`;

export default DropZone;
