export const TOKEN_KEY = 'tokens';

export const storage = localStorage;

export const setToken = ({ accessToken }) => {
  storage.setItem(TOKEN_KEY, JSON.stringify({ accessToken }));
};

export const removeToken = () => storage.removeItem(TOKEN_KEY);

export const getToken = () => {
  const token = storage.getItem(TOKEN_KEY);
  if (!token) {
    return null;
  }

  try {
    return JSON.parse(token);
  } catch (e) {
    return null;
  }
};

export const getAccessToken = () => {
  const token = getToken();
  if (!token) {
    return null;
  }

  return token.accessToken;
};

export const getRefreshToken = () => {
  const token = getToken();
  if (!token) {
    return null;
  }

  return token.refreshToken;
};

export const getExpirationTime = () => {
  const token = getToken();
  if (!token) {
    return null;
  }

  return token.exp;
};

// Refresh token if only left less than 60 minute to expired token
export const isTokenExpired = (period = 60 * 60 * 1000) => {
  const exp = getExpirationTime();
  if (!exp) {
    return false;
  }

  return new Date(exp) - new Date() < period;
};
