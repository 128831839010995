import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DropZoneUpload from '../../DropZoneUpload';
import Spinner from '../../Spinner';

const NotesPopup = ({
  open,
  onClose,
  setNote,
  logos,
  note,
  setLogos,
  images,
  setImages,
  job,
  locationId,
}) => {
  const [notePop, setNotePop] = useState(note);
  const [logosPop, setLogosPop] = useState(logos);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setNotePop(note);
    setLogosPop(logos);
  }, [note, logos]);

  const clearAndClose = () => {
    setNotePop(note);
    setLogosPop([]);
    setIsLoading(false);
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={clearAndClose}>
      <Wrapper>
        <h3>Notes</h3>
        <TextareaAutosize
          aria-label="minimum height"
          rowsMin={6}
          value={notePop}
          placeholder="Type your notes here..."
          onChange={(event) => {
            setNotePop(event.target.value);
          }}
        />
        <h4>Images</h4>
        <AddImg>
          <DropZoneUpload
            multiple={true}
            job={job}
            locationId={locationId}
            value={logosPop}
            setImages={setImages}
            images={images}
            onChange={(file) => {
              setLogosPop(file);
            }}
            setIsLoading={setIsLoading}
          />
        </AddImg>
        <WrapButton>
          <MainButton
            disabled={isLoading}
            onClick={() => {
              setNote(notePop);
              setLogos(logosPop);
              clearAndClose();
            }}
          >
            {isLoading ? <Spinner size={15} color="#fff" /> : 'ADD'}
          </MainButton>
        </WrapButton>
      </Wrapper>
    </StyledDialog>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  min-width: 200px !important;
  color: #333333;
  background: #fafafa;

  textarea {
    padding: 15px;
    width: 481px;
    border-radius: 5px;
    font-family: Roboto;
    font-size: 14px;
    &::placeholder {
      color: #333333;
    }
  }

  @media (max-width: 550px) {
    padding: 20px 15px;
  }
`;

const AddImg = styled.div``;

const WrapButton = styled.div`
  display: flex;
  justify-content: center;
`;

const MainButton = styled.button`
  cursor: pointer;
  background: #61933a;
  border-radius: 5px;
  width: 294px;
  height: 40px;
  color: #fff;
  margin-top: 27px;
  border: none;
  outline: none;
`;

const StyledDialog = styled(Dialog)`
  @media (max-width: 550px) {
    div[role='document'] > div {
      margin: 10px !important;
    }
  }
`;

export default NotesPopup;
