import { connect } from 'react-redux';
import ForgotPasswordPage from './ForgotPasswordPage';
import { forgotPassword } from '../../store/accountStore/actions';

const mapStateToProps = ({
  accountStore: { emailSent, isLoading, error },
}) => ({
  emailSent,
  isLoading,
  error,
});

const mapDispatchToProps = { forgotPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
