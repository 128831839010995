import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

function GuestRoute({ user, location, ...rest }) {
  if (user) {
    if (location.state) {
      return <Redirect to={location.state.from} />;
    } else {
      return <Redirect to={{ pathname: '/' }} />;
    }
  }

  return <Route location={location} {...rest} />;
}

const mapStateToProps = ({ dataStore: { user } }) => ({ user });
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GuestRoute);
