import { connect } from 'react-redux';
import { getJobs } from '../../store/jobsStore/actions';
import JobsListWorkerPage from './JobsListWorkerPage';

const mapStateToProps = ({ jobsStore: { jobByDate, isLoading } }) => ({
  jobByDate,
  isLoading,
});

const mapDispatchToProps = {
  getJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsListWorkerPage);
