import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = ({ size = 40, color = '#61933a' }) => (
  <Wrap>
    <CircularProgress size={size} thickness={3} style={{ color: color }} />
  </Wrap>
);

const Wrap = styled.div`
  height: 50%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
`;

export default Spinner;
