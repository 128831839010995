import * as types from './constants';
import { setToken } from '../../services/api/storage';

const initialState = {
  error: false,
  isLoading: false,
  token: {},
  plans: [],
  emailSent: false,
  passwordChanged: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.USER_LOGIN_REQUEST:
      return { ...state, token: {}, isLoading: true, error: false };
    case types.USER_LOGIN_SUCCESS:
      setToken(payload);
      return { ...state, token: payload, isLoading: false };
    case types.USER_LOGIN_FAIL:
      return { ...state, isLoading: false, error: payload };

    case types.RESET_PASSWORD_REQUEST:
      return { ...state, emailSent: false, isLoading: true, error: false };
    case types.RESET_PASSWORD_SUCCESS:
      return { ...state, emailSent: true, isLoading: false };
    case types.RESET_PASSWORD_FAIL:
      return { ...state, isLoading: false, error: true };

    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        passwordChanged: false,
        isLoading: true,
        error: false,
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return { ...state, passwordChanged: true, isLoading: false };
    case types.CHANGE_PASSWORD_FAIL:
      return { ...state, isLoading: false, error: true };

    default:
      return state;
  }
};
