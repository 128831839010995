import React from 'react';
import Dropzone from 'react-dropzone';
import styled, { css } from 'styled-components';
import DropZone from './DropZone';
import FilePreview from './FilePreview';
import { generateId } from '../../utils/string';
import StyledAlertMessage from '../StyledAlertMessage';
// import RejectLoadingImgPopup from '../../components/Popups/RejectLoadingImgPopup';
import { getErrorsMessage } from '../../utils/template';
import request from '../../services/api/request';
import { getResponseErrors } from '../../utils/response';
import ImageList from './ImageList';
class DropZoneUpload extends React.Component {
  state = {
    isLoading: false,
    files: this.props.value || [],
    rejectLoadingFiles: false,
  };

  // handleChangeRejectLoadingFiles = () => {
  //   this.setState({ rejectLoadingFiles: false });
  // };

  uploadImages(files) {
    const { multiple, field = 'commentImg' } = this.props;

    const form = new FormData();
    files.forEach((file) => form.append(`${field}`, file));
    form.append('multiple', multiple);
    return request({
      method: 'POST',
      url: `/v1/jobs/upload`,
      body: form,
    });
  }

  onDrop = async (uploadFiles) => {
    const { files } = this.state;
    const {
      isNotLimited,
      setImages,
      onChange,
      images,
      setIsLoading,
      value = [],
    } = this.props;
    if (uploadFiles.length > 0) {
      if (!isNotLimited && value.length + uploadFiles.length > 15) {
        StyledAlertMessage.error('You can save only 15 images');
        return;
      }

      try {
        setIsLoading(true);
        this.setState({ isLoading: true });
        const imagesLoad = await this.uploadImages(uploadFiles);
        const listLocationsImg = imagesLoad.map((img) => img.imgUrl);

        const imagesList = [...images, ...imagesLoad];

        const filesList = [...files, ...listLocationsImg];
        this.setState({ files: filesList, isLoading: false }, () => {
          setIsLoading(false);
          onChange(filesList);
          setImages(imagesList);
        });
      } catch (e) {
        const errors = getResponseErrors(e);
        this.setState({ isLoading: false });
        setIsLoading(false);
        StyledAlertMessage.error(getErrorsMessage(errors));
      }
    }
  };

  handleDelete = async (imgUrl) => {
    const { images, onChange, value = [], multiple } = this.props;
    let files = [];

    const image = images.find((img) => img.imgUrl === imgUrl);

    if (multiple) {
      files = value.filter((item) => item !== imgUrl);
    }

    await request({
      method: 'DELETE',
      url: `/v1/jobs/img/${image.imgId}`,
    });

    this.setState({ files }, () => {
      onChange(files);
    });
  };

  onDropRejected = (rejectedFiles) => {
    if (rejectedFiles.length === 1) {
      this.setState({
        rejectLoadingFiles: `File ${rejectedFiles[0].name} is not supported`,
      });
    } else {
      const namesArr = rejectedFiles.map((file) => file.name);
      this.setState({
        rejectLoadingFiles: `Files ${namesArr.join(', ')} are not supported`,
      });
    }
  };

  render() {
    const { isLoading, rejectLoadingFiles } = this.state;
    const {
      pt = 0,
      value = [],
      small = 'false',
      multiple = false,
      handleDrop,
    } = this.props;

    const inputId = generateId('file-upload-');
    return (
      <Wrap
        pt={pt}
        amount={value.length}
        className="drop-zone-wrap"
        multi={multiple && value.length}
      >
        {!handleDrop ? (
          <DragWrapper>
            {value &&
              value.length > 0 &&
              multiple &&
              value.map((item, index) => (
                <FilePreview
                  multiple
                  key={index}
                  file={item}
                  small={small}
                  className="file-preview-drag"
                  onDelete={() => this.handleDelete(item)}
                />
              ))}
            <Dropzone
              accept="image/jpeg, image/jpg, image/png"
              maxSize={5242880}
              multiple={multiple}
              onDrop={this.onDrop}
              onDropRejected={this.onDropRejected}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="drop-zone-content">
                  {value.length > 0 && !multiple && (
                    <FilePreview
                      file={value}
                      small={small}
                      multiple={false}
                      onDelete={this.handleDelete}
                    />
                  )}
                  {(value.length === 0 || (multiple && value.length < 15)) && (
                    <DropZone
                      accept="image/jpeg, image/jpg, image/png"
                      small={small}
                      value={value}
                      inputId={inputId}
                      multiple={multiple}
                      isLoading={isLoading}
                      inputProps={getInputProps()}
                      onDropRejected={this.onDropRejected}
                    />
                  )}
                </div>
              )}
            </Dropzone>
          </DragWrapper>
        ) : (
          <ImageList {...this.props} />
        )}
        {/*<RejectLoadingImgPopup*/}
        {/*  onClose={this.handleChangeRejectLoadingFiles}*/}
        {/*  open={Boolean(rejectLoadingFiles)}*/}
        {/*  text={rejectLoadingFiles}*/}
        {/*/>*/}
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  min-width: 60px;
  min-height: 60px;
  padding-top: ${({ pt }) => pt}px;

  * {
    outline: none !important;
  }

  .drop-zone-content {
    max-width: 100%;
  }

  ${({ multi }) =>
    multi &&
    css`
      display: flex;
      flex-wrap: ${({ amount }) => (amount <= 4 ? 'nowrap' : 'wrap')};

      .drop-zone-content {
        // width: 20%;
        min-width: 100px;
      }

      @media (max-width: 768px) {
        flex-wrap: wrap !important;

        .drop-zone-content {
          max-width: 100% !important;
          width: calc(50% - 16px);
          margin: 0 8px;
        }
      }
    `}
`;

const DragWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default DropZoneUpload;
