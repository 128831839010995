import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CloseGreyImg from '../../assets/svgs/close-grey.svg';
import AddNotesGreen from '../../assets/svgs/notes-green.svg';
import { ReactSVG } from 'react-svg';
import NotesPopup from '../../components/Popups/NotesPopup';

import { Formik, Field, Form } from 'formik';
import { TASK_STATUS_ID_YES, TASK_STATUS_ID_NO } from '../../utils/tasks';
import { isEmpty } from 'lodash';

const getTaskName = (task) => `task-${task.id}`;
const validateRequired = (values, tasks) =>
  tasks.every((task) => Boolean(values[getTaskName(task)]));

const getTaskStatusIdValue = (task, values) =>
  values[getTaskName(task)] === 'yes' ? TASK_STATUS_ID_YES : TASK_STATUS_ID_NO;

const groupTasksByPlace = (tasks) =>
  tasks.reduce((acc, task) => {
    const { taskPlaceId } = task;
    if (!acc[taskPlaceId]) {
      acc[taskPlaceId] = {
        place: task.place,
        tasks: [],
      };
    }

    acc[taskPlaceId].tasks.push(task);
    return acc;
  }, {});

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const VALIDATION_MESSAGE_FIELD_REQUIRED = 'Please select each value';
const VALIDATION_MESSAGE_SRN_REQUIRED = 'Please fill "Snow Removal PO Number"';
const SaveTimerPage = ({
  location,
  activeJob,
  getActiveJob,
  getLocationById,
  match,
  history,
  joinJobImg,
  addNoteToJob,
  createJobTasks,
  tasksCreated,
}) => {
  const [validationMessage, setValidationMessage] = useState('');
  const [openNotes, setOpenNotes] = useState(false);
  const [note, setNote] = useState('');
  const [logos, setLogos] = useState([]);
  const [images, setImages] = useState([]);
  const [srn, setSRN] = useState('');
  const prevPropUpdate = usePrevious(tasksCreated);

  useEffect(() => {
    getActiveJob();
    getLocationById(match.params.locationId);
  }, []);

  useEffect(() => {
    if (
      prevPropUpdate === false &&
      prevPropUpdate !== tasksCreated &&
      tasksCreated
    ) {
      history.push(`/`);
    }
  }, [prevPropUpdate, tasksCreated]);

  let tasks = [];

  if (!isEmpty(location)) tasks = location.tasks;

  const placeMap = groupTasksByPlace(tasks);
  const handleSubmit = (values) => {
    if (!validateRequired(values, tasks)) {
      setValidationMessage(VALIDATION_MESSAGE_FIELD_REQUIRED);
      return;
    }
    if (values?.['task-7'] === 'yes' && !srn) {
      setValidationMessage(VALIDATION_MESSAGE_SRN_REQUIRED);
      return;
    }

    let jobId;
    if (activeJob.length) {
      jobId = activeJob.findIndex(
        (jobItem) => jobItem.locationId === +match.params.locationId,
      );
    } else {
      jobId = activeJob.id;
    }

    if (logos.length) {
      const imagesFiltered = images.filter((x) =>
        logos.some((y) => x.imgUrl === y),
      );

      const imagesIds = imagesFiltered.map((image) => image.imgId || image.id);

      joinJobImg(activeJob.length ? activeJob[jobId].id : jobId, {
        imgIds: [...imagesIds],
      });
    }

    const answers = tasks.map((task) => ({
      jobId: activeJob.length ? activeJob[jobId].id : jobId,
      taskId: task.id,
      taskStatusId: getTaskStatusIdValue(task, values),
    }));

    createJobTasks({
      jobId: activeJob.length ? activeJob[jobId].id : jobId,
      payload: { tasks: answers, snowRemovalOrderId: srn },
    });

    addNoteToJob({
      jobId: activeJob.length ? activeJob[jobId].id : jobId,
      comment: note,
    });
  };

  const handleChange = () => {
    if (validationMessage === VALIDATION_MESSAGE_FIELD_REQUIRED) {
      setValidationMessage('');
    }
  };

  return (
    <Wrapper>
      <div className="modal-header">
        <h2>Save entry</h2>
        <div
          className="close-btn"
          onClick={() => history.push(`/timer/${match.params.locationId}`)}
        >
          <ReactSVG src={CloseGreyImg} />
        </div>
      </div>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ values }) => (
          <Form onChange={handleChange}>
            <p>Services performed at this location:</p>
            {Object.keys(placeMap).map((placeId) => {
              placeMap[placeId].tasks.sort(
                (taskOne, taskTwo) => taskOne.id - taskTwo.id,
              );
              return (
                <Fragment key={placeId}>
                  <h2>{placeMap[placeId].place.name}</h2>
                  {placeMap[placeId].tasks.map((task) => (
                    <div key={task.id}>
                      <Row>
                        <span className="task-name">
                          {task.name.toLocaleLowerCase()}
                        </span>
                        <div className="switch-field">
                          <Field
                            type="radio"
                            name={getTaskName(task)}
                            id={`yes-${task.id}`}
                            value="yes"
                          />
                          <label htmlFor={`yes-${task.id}`}>Yes</label>
                          <Field
                            type="radio"
                            name={getTaskName(task)}
                            id={`no-${task.id}`}
                            value="no"
                          />
                          <label htmlFor={`no-${task.id}`}>No</label>
                        </div>
                      </Row>
                      {task.id === 7 && values?.['task-7'] === 'yes' && (
                        <WrapperInput>
                          <label htmlFor="">Snow Removal PO Number</label>
                          <input
                            type="text"
                            defaultValue={srn}
                            onChange={(event) => setSRN(event.target.value)}
                          />
                        </WrapperInput>
                      )}
                    </div>
                  ))}
                </Fragment>
              );
            })}
            <Error>{validationMessage}</Error>
            <Divider />
            {(note || Boolean(logos.length)) && (
              <div>
                <h3>Notes</h3>
                <p>{note}</p>
                <WrapImgs>
                  {logos.map((logo, index) => (
                    <WrapImg key={index}>
                      <img src={logo} alt="" />
                    </WrapImg>
                  ))}
                </WrapImgs>
              </div>
            )}
            <AddNotes onClick={() => setOpenNotes(true)}>
              <ReactSVG src={AddNotesGreen} />
              {!note && !logos.length ? (
                <div>ADD NOTES</div>
              ) : (
                <div>EDIT NOTES</div>
              )}
            </AddNotes>
            <NotesPopup
              joinJobImg={joinJobImg}
              addNoteToJob={addNoteToJob}
              createJobTasks={createJobTasks}
              job={activeJob}
              // answers={answers}
              note={note}
              setNote={setNote}
              logos={logos}
              setLogos={setLogos}
              images={images}
              setImages={setImages}
              open={openNotes}
              locationId={+match.params.locationId}
              onClose={() => setOpenNotes(false)}
            />
            <WrapButton>
              <MainButton type="submit">SAVE</MainButton>
            </WrapButton>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};
const Error = styled.span`
  color: #d60000;
`;
const WrapButton = styled.div`
  display: flex;
  justify-content: center;
`;
const MainButton = styled.button`
  cursor: pointer;
  background: #61933a;
  border-radius: 5px;
  height: 50px;
  color: #fff;
  margin-top: 80px;
  border: none;
  outline: none;
  width: 240px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 28px;
  align-items: baseline;
  span {
    font-weight: bold;
  }
  .task-name {
    text-transform: capitalize;
    font-weight: 400;
  }
`;

const Wrapper = styled.div`
  padding: 20px 30px;
  min-width: 200px !important;
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
  }
  .close-btn {
    cursor: pointer;
  }
  .switch-field {
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 4px;
  }
  .switch-field input {
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    width: 1px;
    border: 0px;
    overflow: hidden;
    position: absolute !important;
  }
  .switch-field label {
    background: rgb(248, 248, 248);
    color: rgb(51, 51, 51);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    line-height: 34px;
    padding: 0;
    width: 60px;
    height: 34px;
    font-weight: bold;
    transition: all 0.1s ease-in-out 0s;
  }
  .switch-field input:checked + label {
    background-color: rgb(97, 147, 58);
    color: rgb(255, 255, 255);
    box-shadow: none;
    border-radius: 3px;
  }
  .cta {
    text-align: center;
    margin-top: 30px;
  }
  .cta button {
    height: 50px;
    width: 240px;
    line-height: 50px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: bold;
    border: 0;
    color: #fff;
    border-radius: 4px;
    background: #61933a;
    outline: none;
  }

  @media (max-width: 550px) {
    padding: 20px 15px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #dddddd;
`;

const AddNotes = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  color: #61933a;
  svg {
    margin-right: 15px;
  }
`;

const WrapImg = styled.div`
  height: 80px;
  width: auto;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  margin-right: 15px;

  img {
    border-radius: 5px;
    margin: 0 auto;
    height: 80px !important;
    width: 80px !important;
    max-width: 80px !important;
  }
`;

const WrapImgs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
`;

const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 28px;
  margin-bottom: 20px;
  height: 38px;
  align-items: center;
  input {
    outline-color: #61933a;
    width: 128px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
  }
`;

export default SaveTimerPage;
