import * as types from './constants';

const initialState = {
  user: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_USER_REQUEST:
      return { ...state };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case types.GET_USER_FAIL:
      return { ...state };

    default:
      return state;
  }
};
