import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

export default class Form extends React.Component {
  state = {
    values: null,
  };

  handleSubmit = (values) => {
    const { email } = this.props;
    this.props.resetPassword({ email, password: values.password });
  };

  render() {
    const { isLoading } = this.props;

    return (
      <Formik
        initialValues={{
          password: '',
          ['password-confirm']: '',
        }}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(8, ' must be at least 8 characters')
            .required(' is required'),
          ['password-confirm']: Yup.string()
            .min(8, ' must be at least 8 characters')
            .required(' is required')
            .oneOf([Yup.ref('password'), null], ' do not match')
            .required(' is required'),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form id="reset-form" noValidate="" onSubmit={handleSubmit}>
              <div
                className={
                  errors.password && touched.password
                    ? 'form-row has-error'
                    : 'form-row'
                }
              >
                <label htmlFor="password">New password</label>
                <input
                  data-testid="password"
                  id="password"
                  value={values.password}
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="form-error-message">
                  Password
                  {errors.password}
                </div>
              </div>
              <div
                className={
                  errors['password-confirm'] && touched['password-confirm']
                    ? 'form-row has-error'
                    : 'form-row'
                }
              >
                <label htmlFor="password-confirm">Confirm new password</label>
                <input
                  data-testid="password-confirm"
                  id="password-confirm"
                  value={values['password-confirm']}
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="form-error-message">
                  Passwords
                  {errors['password-confirm']}
                </div>
              </div>
              <div className="form-row">
                <button
                  disabled={!isEmpty(errors) || isLoading}
                  className="btn"
                  type="submit"
                >
                  <div className="btn-text">Change password</div>
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}
