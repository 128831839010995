import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Form from './Form';
import { SentDialog } from './SentDialog';
import StyledAlertMessage from '../../components/StyledAlertMessage';
import LogoImg from '../../assets/images/logo.png';

class ForgotPasswordPage extends React.Component {
  state = {
    sent: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.emailSent && this.props.emailSent) {
      this.setState({ sent: true });
    }
    if (!prevProps.error && this.props.error) {
      StyledAlertMessage.error('The email address is invalid');
    }
  }

  render() {
    const {
      history: { goBack },
      forgotPassword,
      isLoading,
    } = this.props;

    const { sent } = this.state;

    return (
      <div className="container">
        <div className="logo">
          <img src={LogoImg} />
        </div>
        <Wrap className="card login">
          <p className="card-title">Forgot your password?</p>
          {!sent && (
            <p className="center">
              Enter the email address you use to sign in, and we’ll send you a
              link to reset your password.
            </p>
          )}
          {sent ? (
            <SentDialog />
          ) : (
            <Form
              isLoading={isLoading}
              goBack={goBack}
              forgotPassword={forgotPassword}
            />
          )}
        </Wrap>
      </div>
    );
  }
}
const Wrap = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;

export default withRouter(ForgotPasswordPage);
