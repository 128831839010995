import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reducers from './reducers';
import rootSaga from './sagas';
import { persistConfig } from './reducers';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware),
  ),
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
