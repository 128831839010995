import React, { Fragment } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import Spinner from '../../components/Spinner';
import { ReactSVG } from 'react-svg';
import CloseGreyImg from '../../assets/svgs/close-grey.svg';
import FilePreview from './FilePreview';
import ImagePopup from '../../components/ImagePopup';

class LocationInfoPage extends React.Component {
  state = {
    popupImg: false,
  };

  componentDidMount() {
    const {
      getLocationById,
      match: {
        params: { locationId },
      },
    } = this.props;

    getLocationById(locationId);
  }

  handleImgPopup = (src) => {
    this.setState({ popupImg: src ? src : false });
  };

  render() {
    const { history, location, isLoading } = this.props;
    const { popupImg } = this.state;

    return (
      <Wrapper>
        <div className="modal-header">
          <h2>Location info</h2>
          <div className="close-btn" onClick={() => history.goBack()}>
            <ReactSVG src={CloseGreyImg} />
          </div>
        </div>

        {isLoading && isEmpty(location) && <Spinner />}

        {!isLoading && !isEmpty(location) && (
          <>
            <Address>
              <div className="main">{location.name}</div>
              <span className="full-address">
                {location.address}
                {location.city ? ',' : ''} {location.city}
                {location.province ? ',' : ''} {location.province}
                {location.postalCode}
              </span>
            </Address>
            <WrapNote>
              <h2>Notes</h2>
              <span>{location.comment}</span>
            </WrapNote>
            <WrapImg>
              {location.img.map((image) => (
                <FilePreview
                  key={image.imgUrl}
                  src={image.imgUrl}
                  handleImgPopup={this.handleImgPopup}
                />
              ))}
            </WrapImg>
          </>
        )}
        <ImagePopup
          open={popupImg}
          src={popupImg}
          onClose={this.handleImgPopup}
        />
        <ButtonsWrap>
          <Button onClick={() => history.goBack()}>CLOSE</Button>
        </ButtonsWrap>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 20px 30px;
  min-width: 200px !important;
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    border-bottom: 1px solid #dddddd;
  }
  .close-btn {
    cursor: pointer;
  }
`;

const Address = styled.div`
  margin-top: 25px;

  .main {
    font-size: 18px;
    display: block;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .full-address {
    color: #777;
  }
`;

const WrapNote = styled.div`
  margin-top: 25px;
`;

const WrapImg = styled.div`
  display: flex;
  .box {
    margin-right: 15px;
  }
`;

const ButtonsWrap = styled.div`
  position: fixed;
  bottom: 30px;
  left: calc(50% - 120px);
`;

const Button = styled.button`
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #61933a;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px 92px;
  width: 240px;
`;

export default LocationInfoPage;
