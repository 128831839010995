import { getAccessToken, getRefreshToken } from './storage';
import { API_URL } from '../../config';

const toJSON = (data) =>
  typeof data === 'string' ? data : JSON.stringify(data);

const getResponseErrorData = async (res) => {
  try {
    return await res.json();
  } catch (e) {
    return res;
  }
};

const getRequestToken = (typeRefresh) => {
  if (typeRefresh) {
    return getRefreshToken();
  }

  return getAccessToken();
};

const request = async ({ url, headers, method, body, json }) => {
  const token = getRequestToken(url === '/refresh-tokens');
  const options = {
    method,
    body,
    ...(json && { body: toJSON(json) }),
    headers: {
      Accept: 'application/json',
      ...(token && { Authorization: `${token}` }),
      ...(json && { 'Content-Type': 'application/json' }),
      ...headers,
    },
  };

  const res = await fetch(API_URL + url, options);
  if (res.status >= 400) {
    throw await getResponseErrorData(res);
  }

  if (res.status === 204) {
    return res;
  }

  return res.json();
};

export default request;
