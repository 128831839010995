import React from 'react';
import { Link } from 'react-router-dom';

export const ResetDialog = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <p>Your password was successfully changed.</p>
      <p className="links">
        <Link to="/login">Sign In</Link>
      </p>
    </div>
  );
};
